import React from "react";

/**
 * Cria um componente que mantem todos os items alinhados a direita
 *
 * @param {React.ReactNode} children - Aceita somente NAVBARITEM ou NAVBARDROPDOWN.
 * @returns {JSX.Element} O componente React.
 */

export const NavbarEnd = ({children}) => {
    return(
          <div className="navbar-end">
            {children}
          </div>
    )
}