// @ts-nocheck
import React from 'react';


export const PageHeroBody = ({ children, style={}, className=""}) => {
  return (
    <div className={`hero-body ${className}`} style={style}>
      {children}
    </div>
  );
};
