import React from "react";


export const InputReadOnly = ({children, className="", color='is-light', size="", isLoading=false, disabled=null}) => {


    return(           
        <input 
            className={`input ${color} ${size} ${isLoading ? "is-loading" : ""} ${className}`}  
            type="text"
            value={children}
            disabled={disabled}
            readOnly={true}
        />
    )
}

