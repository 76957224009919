import React from "react";

export const InputContents = ({children, alignIcon=null}) => {
    return(           
        <div className={`control has-icons-${alignIcon}`}>
            {children}
        </div>
)
}

