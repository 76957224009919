import { CardRoot } from "./CardRoot";
import { CardFooter } from "./CardFooter";
import { CardHeader } from "./CardHeader";
import { CardContents } from "./CardContents";
import { CardButton } from "./CardButton";
import { CardImage } from "./CardImage";

/**
 * # Root
 * 
 * Cria um componente "Card" do bulma.
 *
 * @param {React.ReactNode} children - Aceita qualquer componente react, mas de preferencial os componentes do CARD.
 * @param {string} className - Recebe uma classe qualquer classe do bulma.
 * @param {React.CSSProperties} style - Recebe um classe de style do REACT
 * @returns {JSX.Element} O componente React.
 * 
 * # Imagem
 * 
 * Cria um componente de imagem para o card
 *
 * @param {string} className - Recebe uma classe qualquer classe do bulma.
 * @param {image} img - Recebe uma imagem.
 * @param {string} alt - Recebe um string que aparecerá caso a imagem não carregue.
 * @returns {JSX.Element} O componente React.
 * 
 * # Header
 * 
 * Cria um componente de header para o card.
 *
 * @param {React.ReactNode} children - Aceita qualquer componente react.
 * @param {string} title - Recebe um string que será o titulo do card.
 * @returns {JSX.Element} O componente React.
 * 
 * # Footer
 * 
 * Cria um componente para receber todo conteúdo do footer para o card
 *
 * @param {React.ReactNode} children - Aceita qualquer componente react.
 * 
 * # Contents
 * 
 * Cria um componente para receber todo conteúdo do card.
 *
 * @param {React.ReactNode} children - Aceita qualquer componente react.
 * @returns {JSX.Element} O componente React.
 * 
 * # Button
 * 
 * Cria um componente botão para o card footer.
 *
 * @param {React.ReactNode} children - Aceita String que parecerá dentro do botão, ou qualquer componente react.
 * @param {string} className - Recebe uma classe qualquer classe do bulma.
 * @param {string} colorText - Recebe uma classe de cor de texto do bulma ("has-text-white", "has-text-black", "has-text-light", "has-text-dark", "has-text-primary", "has-text-link", "has-text-info", "has-text-success", "has-text-warning", "has-text-danger")
 * @param {string} link - Recebe um path que redirecionará o usuário para a paginá desejada ("/caminhoDaPagina")
 * @returns {JSX.Element} O componente React.
 */


export const Card =  {
    Root:CardRoot,
    Header:CardHeader,
    Content:CardContents,
    Footer:CardFooter,
    Button:CardButton,
    Image:CardImage,
}


//    <Card.Root>
//      <Card.Image img={algumaImagemImportada} alt="Mensagem caso Erro" />
//      <Card.Header title='card'/>
//      <Card.Content>
//      </Card.Content>
//      <Card.Footer>
//      <Card.Button colorText={'danger'}>
//    recusar
//      </Card.Button>
//      <Card.Button colorText={'success'}  >
//    aceitar
//      </Card.Button>
    
//      </Card.Footer>
//    </Card.Root>