import React from "react";

export const FileInput = ({setFile, field=null, accepts="", required=null}) => {


    const ImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            if(field){
                setFile((prev) => ({ ...prev, [field]: event.target.files[0] }));
            }
            else{
                setFile(event.target.files[0]);
            }
            
        }
      };

    return(
        <input class="file-input" type="file" accept={accepts} onChange={ImageChange} required={required}/>
    )
}