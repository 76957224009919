import { PageRoot } from "./PageRoot";
import { PageSideBar } from "./PageSideBar";
import { PageContent } from "./PageContent";
import { PageSection } from "./PageSection";

/**
 * # Root
 * 
 * Cria um componente com a classe "container".
 *
 * @param {React.ReactNode} children - O conteúdo a ser exibido dentro do componente.
 * @param {React.CSSProperties} style - Estilo CSS adicional para o componente.
 * @param {string} className - Classes adicionais para o componente.
 * @param {string} color - Classes de cores do bulma ("has-background-white", "has-background-black", "has-background-light", "has-background-dark", "has-background-primary", "has-background-link", "has-background-info", "has-background-success", "has-background-warning", "has-background-danger")
 * @param {string} size - Classes de tamanhos do bulma ( "is-widescreen", "is-max-widescreen", "is-fullhd", "is-fluid", "is-max-desktop", "is-max-tablet")
 * @returns {JSX.Element} O componente React.
 * 
 * # Content
 * 
 * Cria um componente com a classe "content".
 *
 * @param {React.ReactNode} children - O conteúdo a ser exibido dentro do componente.
 * @param {React.CSSProperties} style - Estilo CSS adicional para o componente.
 * @param {string} className - Classes adicionais para o componente.
 * @returns {JSX.Element} O componente React.
 * 
 * # Sidebar
 * 
 * Cria um componente para alocar um "sidebar".
 *
 * @param {React.ReactNode} children - O conteúdo a ser exibido dentro do componente.
 * @returns {JSX.Element} O componente React.
 * 
 * # Section
 * 
 * Cria um componente com a classe "container".
 *
 * @param {React.ReactNode} children - O conteúdo a ser exibido dentro do componente.
 * @param {React.CSSProperties} style - Estilo CSS adicional para o componente.
 * @param {string} className - Classes adicionais para o componente.
 * @param {string} size - Classes de tamanhos do bulma ("is-medium", "is-large")
 * @returns {JSX.Element} O componente React.
 */

export const Page = {
    Root: PageRoot,
    Content: PageContent,
    SideBar: PageSideBar,
    Section: PageSection
}



//     <Page.Root>
//         <Page.SideBar>
//             <SideBar.Root>
//                  ...
//             </SideBar.Root>
//         </Page.SideBar>

//         <Page.Content>
//              <Page.Section>
//                  <Page.Container>
//                      Aqui ficará o conteúdo
//                  <Page.Container/>
//              <Page.Section/>
//         </Page.Content>
//     </Page.Root>
