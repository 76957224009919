import { FileContents } from "./FileContents";
import { FileIcon } from "./FileIcon";
import { FileInput } from "./FileInput";
import { FileLabel } from "./FileLabel";
import { FileRoot } from "./FileRoot";

export const File = {
    Root:FileRoot,
    Input:FileInput,
    Contents:FileContents,
    Label:FileLabel,
    Icon: FileIcon,
}