import React from 'react';
import { Page } from '../../../components/layouts/page';
import { Icon } from '../../../components/icons';
//import { useGlobalContext } from '../../../context/ContextGlobal';
import { Button } from '../../../components/ui/button';
import { logOut } from '../../../firebase/auth';

export const AHome = () => {

    //const {setErrors} = useGlobalContext();

    // useEffect(()=>{
    //     setErrors("login expired");
    // },[])

    return (
            <>            
            <Page.Root>
                {/* <Page.SideBar>
                    <SideBar.Root>
                        <SideBar.List>
                            <SideBar.Item title={"Informações"} link={"/eventos/informações"}>
                                <Icon size={30}>info</Icon>
                            </SideBar.Item>
                            <SideBar.Item title={"Colaboradores"} link={"/"}>
                                <Icon size={30}>users</Icon>
                            </SideBar.Item>
                        </SideBar.List>
                    </SideBar.Root>
                </Page.SideBar> */}

                <Page.Content style={{marginTop: 150}} className='has-text-centered'>
                    <Icon size={150}>gear</Icon>
                    <br />
                    Página em construção....
                    {/* {JSON.stringify(user)} */}
                    <Button.Bt action={async ()=>{logOut(); }}> teste</Button.Bt>
                </Page.Content>
            </Page.Root>
            </>
    );
};
