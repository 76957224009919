import React from 'react';
import { Navbar } from '../../components/layouts/navbar';
import Logo from '../../assets/logo_temporario.png' 
import { Outlet, useNavigate } from 'react-router-dom';
import { AlertFunctional } from '../../components/ui/alert/AlertFunctional';
import { useGlobalContext } from '../../context/ContextGlobal';
import { logOut } from '../../firebase/auth';
import { RemoveItemSession } from '../../sessionStorage';

export const ARoot = () => {

    const {alerts, setAlerts, navBar, setNavBar, setUser, user} = useGlobalContext();

    const navigate = useNavigate();

    const sair = () => {
        logOut();
        RemoveItemSession({key:'user'});
        setUser([]);
        navigate('/');
    }

    return (
            <>
            <Navbar.Root active={navBar} setActive={setNavBar} logo={Logo}>
                <Navbar.End>
                    <Navbar.ItemInfo colorText={"has-text-grey mr-5"}>
                        <p class="control">
                        <figure class="image is-48x48 mr-3 mt-1">
                                <img class="is-rounded" src={user?.user?.url_foto}  alt=""/>
                            </figure>
                        </p>
                        <p class="control is-flex is-justify-content-center">
                            <label className='mt-4'>{`${user.user.nome}`}</label>
                        </p>
                    </Navbar.ItemInfo>
                    <Navbar.Item  link={"/administrator/configuracoes"}>Configurações</Navbar.Item>
                    <Navbar.Item  action={sair}>Sair</Navbar.Item>
                </Navbar.End>
            </Navbar.Root>
            
            {/* Faz com que o conteudo mude mudando de pagina */}
            <Outlet/>
            <AlertFunctional values={alerts} setValues={setAlerts}></AlertFunctional>
            </>
    );
};
