import React, { useState } from 'react';
import { useGlobalContext } from '../../../context/ContextGlobal';
import { useNavigate, useParams } from 'react-router-dom';
import { UserFormRoot } from './_FormUser';
import { Page } from '../../../components/layouts/page';
import { Column } from '../../../components/layouts/columns';
import { Form } from '../../../components/ui/form';
import { EditUser } from '../../../funtions/requests';
import { TratamentoError } from '../../../funtions/erros';


export const AEditarUsuario = () => {

    const {uid} = useParams();
    const { setAlerts, user, userContext } = useGlobalContext();
    const [ userInfos, setUserInfo ] = useState(Object.values(userContext?.users || {}).find(user => user.id === uid));
    const setores = userContext?.setores;
    const [ loading, setLoading ] = useState(false);
    const navigate = useNavigate();
    const clientId = user.client.id;

    const editarUsuario = async  () => {
        try{
            //deixa os botões em formato loading
            setLoading(true);
            delete userInfos.id;
            const newUserInfos = {...userInfos, "uid":uid}
            await EditUser(newUserInfos, clientId, setLoading, setAlerts, navigate);
        }   
        catch(error){
            return setAlerts((prev) => ([...prev, {title:'Error:', msg:TratamentoError(error) , type:'error'}]));
        }
        finally{
            setLoading(false);
        }
    }

    return (
        <Page.Section className='is-fullheight-with-navbar'>
            <Form.Root action={() => {editarUsuario();}}>
                    <Column.Root>
                        <Column.Content>
                            <p className="subtitle is-size-3">Usuário</p>
                        </Column.Content>
                    </Column.Root>
                    <UserFormRoot loading={loading} values={userInfos} setValues={setUserInfo} permissions={setores} disabledList={{email:true}} uid={uid}/>
            </Form.Root >
        </Page.Section>
    );
};
