import React from 'react';


export const PageRoot = ({ children, style={}, className="", color="", size=""}) => {
  return (
    <div className={`container ${className} ${color} ${size}`} style={style}>
      {children}
    </div>
  );
};
