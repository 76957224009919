import React from "react";
import { Link } from "react-router-dom";

export const PaginationLink = ({children, link=null, className=""}) => {

    return (
        <li>
            <Link to={link} className={`pagination-link ${className}`}>
                {children}
            </Link>
        </li>
    )
}