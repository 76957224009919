import React, { createContext, useState, useContext, useEffect } from "react";
import { GetItemSession } from "../sessionStorage";

// Crie o contexto
const GlobalContext = createContext();

// Componente Provider
export const ContextGlobal = ({ children }) => {
    
    //adicionar aqui o states para ser usados nas telas seguintes
    const [user, setUser] = useState(GetItemSession('user'));
    const [alerts, setAlerts] = useState([]);
    const [navBar, setNavBar] = useState(false);
    const [errors, setErrors] = useState({});
    const [userContext, setUserContext] = useState({}); // state usado para tela de usuários
    const [setoresContext, setSetoresContext,] = useState({}); // state usado para tela de setores
    const [plansContext, setPlansContext] = useState({}); // state usado para tela de Planos
    const [documentsContext, setDocumentsContext] = useState({}); // state usado para tela de Documentos
    const [consentimentosContext, setConsentimentosContext] = useState({}); // state usado para tela de Consentimentos

    useEffect(() => {
        const atualizaUser = () => {
            setUser(GetItemSession('user'));
        };
    
        window.addEventListener('storage', atualizaUser);
        return () => window.removeEventListener('storage', atualizaUser);
    }, []);

    const contexts = {
        user, setUser,
        alerts, setAlerts,
        navBar, setNavBar,
        errors, setErrors,
        userContext, setUserContext,
        setoresContext, setSetoresContext,
        plansContext, setPlansContext,
        documentsContext, setDocumentsContext,
        consentimentosContext, setConsentimentosContext
    }

    return (
        <GlobalContext.Provider value={contexts}>
        {children}
        </GlobalContext.Provider>
    );
};

// Hook para consumir o contexto
export const useGlobalContext = () => {
    return useContext(GlobalContext);
};
