import React from "react";


export const InputTextarea = ({type="text", placeholder="", className="", color='is-light', size="", rows="5", isLoading=false, value, setValue, field=null, required=null, disabled=null}) => {

    const changes = (e) => {
        if (field) {
            setValue( prev => ({ ...prev, [field]: e.target.value }) );
        } else {
            setValue(e.target.value);
        }
    };

    return(           
        <textarea 
            className={`textarea is-${color} ${size} ${isLoading ? "is-loading" : ""} ${className}`} 
            type={type} 
            placeholder={placeholder}
            value={field ? value[field] : value}
            onChange={changes}
            required={required}
            disabled={disabled}
            rows={rows}
        />
    )
}

