import React, { useState } from "react";
import { Link } from "react-router-dom";



export const SidebarItem = ({children, title="", link=null}) => {

  const [tooltipVisible, setTooltipVisible] = useState(false);

  let tooltip_classe = `tooltip has-background-grey-lighter has-text-dark has-shadow is-size-6 px-5 py-4 ${tooltipVisible ? 'visible' : ''}`

  return (
    <li style={{marginRight:-15}}>
      <div className="tooltip-container">
        <p className={tooltip_classe}>
          {title}
        </p>
        <div>
          <Link className="has-text-dark has-text-centered" to={link} onMouseEnter={() => setTooltipVisible(true)} onMouseLeave={() => setTooltipVisible(false)}>
            {children}
          </Link>
        </div>
      </div>
    </li>
  );
};