import React from 'react';
import { useGlobalContext } from '../../context/ContextGlobal';
import { Card } from '../../components/ui/card';
import { Icon } from '../../components/icons';
import { Button } from '../../components/ui/button';
import { logOut } from '../../firebase/auth';
import { useNavigate } from 'react-router-dom';
import { Column } from '../../components/layouts/columns';
import { Pagination } from '../../components/ui/pagination';
import { RemoveItemSession, SetItemSession } from '../../sessionStorage';


export const LoginSelectUser = () => {
    const navigate = useNavigate();
    const {user, setUser} = useGlobalContext();

    const voltar = () => {
        logOut();
        RemoveItemSession({key:'user'});
        setUser([]);
        navigate('/');
    }
    
    try{

    
        const logar = (user) => {
            SetItemSession({key:'user', value:user});
            setUser(user);
            if(user?.user?.type === "admin"){
                navigate('/administrator');
            }
            else if(user?.user?.type === "user"){
                navigate('/cliente');
            }
            
        }
    
        return (  
            <>
            <Column.Root className='is-mobile has-text-centered'>
            <Column.Content>
            <Card.Root>
                <Card.Content>
                <Column.Root className='is-mobile has-text-centered ml-2'>
                    <Column.Content size='is-1'>
                        <Pagination.Root align='is-left' className='is-rounded ml-2 mt-2' size='is-medium'>
                            <Pagination.List>
                                <Button.Bt action={() => {voltar();}}  className={'is-rounded'}>
                                    <Icon>arrowLeft</Icon>
                                </Button.Bt>
                            </Pagination.List>
                        </Pagination.Root>
                    </Column.Content>
                    <Column.Content>
                       <p className='mt-5 is-size-4'> Selecione uma empresa:</p>
                    </Column.Content>
                </Column.Root>
                    

                    <Column.Root className='is-mobile has-text-centered'>
                        <Column.Content>
                        {user.map((u, index)=>{
                            return(
                                <Button.Bt action={() => {logar(u);}} className='my-2 is-fullwidth' key={`${index}_button`} disabled={!u.user.status}>
                                    <Card.Root className='has-text-left ' style={{width: "100%"}} key={`${index}_cardroot`}>
                                        <Card.Header title={u.client.nome} />
                                        <Card.Content key={`${index}_cardcontent`}>
                                            <Icon className='mr-4' size={20} key={`${index}_iconuser`}>user</Icon>{u.user.nome}
                                            <br />
                                            <p className="has-text-grey-light">
                                                <Icon className='mr-4' size={20} key={`${index}suitcase`}>suitcase</Icon>
                                                {String(u.user.cargo)}
                                            </p>
                                            <p className="has-text-grey-light">
                                                <Icon className='mr-4' size={20} key={`${index}_icongear`}>gear</Icon>
                                                {String(u.user.type).toUpperCase()}
                                            </p>
                                            {!u.user.status && <>
                                            <p className="has-text-danger">
                                                Acesso bloqueado pela empresa
                                            </p>  
                                            </>}
                                        </Card.Content>
                                    </Card.Root>
                                </Button.Bt>
                            )
                        })}
                        </Column.Content>
                    </Column.Root>

                    
                </Card.Content>
            </Card.Root>
            </Column.Content>
                    </Column.Root>
            </>             
        );
    }
    catch(error){
        return voltar();
    }
    
};
