import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { Login } from "../screens/login/_Login";
import { LoginSelectUser } from "../screens/login/_SelectUser";
import { useGlobalContext } from "../context/ContextGlobal";
import { Protections } from "../components/layouts/protections";
import { LoginRoot } from "../screens/login";
import { ARoot } from "../screens/administrator";
import { AHome } from "../screens/administrator/home";
import { URoot } from "../screens/user/home";
import { UHome } from "../screens/user/home/home";
import { Esquecisenha } from "../screens/login/_EsqueciSenha";
import { PrimeiroAcesso } from "../screens/login/_PrimeiroAcesso";
import { AConfiguracoes } from "../screens/administrator/configuracoes";
import { AUsuarios } from "../screens/administrator/usuarios";
import { AAdicionarUsuario } from "../screens/administrator/usuarios/_AdicionarUsuario";
import { ASetores } from "../screens/administrator/setor";
import { AAdicionarSetor } from "../screens/administrator/setor/_AdicionarSetor";
import { AEditarUsuario } from "../screens/administrator/usuarios/_EditarUsuario";
import { AEditarSetor } from "../screens/administrator/setor/_EditarSetor";
import { useEffect, useState } from "react";
import ErrorBoundary from "../components/layouts/errors/handlingError";
import { APlanos } from "../screens/administrator/planos";
import { AAdicionarPlanos } from "../screens/administrator/planos/_AdicionarPlanos";
import { AEditarPlanos } from "../screens/administrator/planos/_EditarPlanos";
import { ADocumentos } from "../screens/administrator/documentos";
import { AAdicionarDocumentos } from "../screens/administrator/documentos/_AdicionarDocumentos";
import { AEditarDocumentos } from "../screens/administrator/documentos/_EditarDocumentos";
import { AConsentimentos } from "../screens/administrator/consentimentos";
import { AAdicionarConsentimentos } from "../screens/administrator/consentimentos/_AdicionarConsentimento";
import { AEditarConsentimentos } from "../screens/administrator/consentimentos/_EditarConsentimento";
import { AInformacoesCliente } from "../screens/administrator/configuracoes/_InformacoesCliente";

export const RouterProviderRoot = () => {
    const { user, setAlerts } = useGlobalContext();

    const [permissions, setPermissions] = useState(user?.user?.permissions || {});

    useEffect(()=>{
      setPermissions(user?.user?.permissions || {});
    },[user, setPermissions])

    const router = createBrowserRouter([
      {
        path: "/",
        element:<LoginRoot />,
        children:[
          {
            path:"/",
            element: <Login />
          },
          {
            path:"esquecisenha",
            element: <Esquecisenha />
          },
          {
            path:"primeiroacesso/:email",
            element: <PrimeiroAcesso />
          },
          {
            path:"selectuser",
            element:<Protections.Redirect permission={Object.values(user).length > 0}> 
                      <LoginSelectUser/>
                    </Protections.Redirect>,
          },
        ]
    },
    {
      path:'/administrator',
      element:<ErrorBoundary setAlerts={setAlerts}>
                <ARoot />,
              </ErrorBoundary>,
      children: [
        {
          path:'/administrator',
          element: <AHome />,         
        },
        {
          path:'/administrator/configuracoes',  
          element: <AConfiguracoes />,
          children:[
            {
              path:'/administrator/configuracoes',
              element: <AInformacoesCliente />,
            },
            {
              path:'/administrator/configuracoes/usuarios',
              element: 
                  <Protections.Permition permission={permissions?.usuarios_vizualizar}>
                    <AUsuarios />
                  </Protections.Permition>,
            },
            {
              path:'/administrator/configuracoes/usuarios/adicionar',
              element:
              <Protections.Permition permission={permissions?.usuarios_criar}>
                  <AAdicionarUsuario />
              </Protections.Permition>,
            },
            {
              path:'/administrator/configuracoes/usuarios/:uid',
              element: 
              <Protections.Permition permission={permissions?.usuarios_editar}>
                  <AEditarUsuario />
              </Protections.Permition>,
            },
            {
              path:'/administrator/configuracoes/setores',
              element:
              <Protections.Permition permission={permissions?.setores_vizualizar}>
                  <ASetores />
              </Protections.Permition>,
            },
            {
              path:'/administrator/configuracoes/setores/adicionar',
              element:
              <Protections.Permition permission={permissions?.setores_criar}>
                <AAdicionarSetor />
              </Protections.Permition>,
            },
            {
              path:'/administrator/configuracoes/setores/:id',
              element: 
              <Protections.Permition permission={permissions?.setores_editar}>
                  <AEditarSetor />
              </Protections.Permition>,
            },
            {
              path:'/administrator/configuracoes/planos',
              element: 
              <Protections.Permition permission={permissions?.planos_vizualizar}>
                  <APlanos />
              </Protections.Permition>,
            },
            {
              path:'/administrator/configuracoes/planos/adicionar',
              element: 
              <Protections.Permition permission={permissions?.planos_criar}>
                  <AAdicionarPlanos />
              </Protections.Permition>,
            },
            {
              path:'/administrator/configuracoes/planos/:id',
              element: 
              <Protections.Permition permission={permissions?.planos_editar}>
                  <AEditarPlanos />
              </Protections.Permition>,
            },
            {
              path:'/administrator/configuracoes/documentos',
              element: 
              <Protections.Permition permission={permissions?.documentos_vizualizar}>
                  <ADocumentos />
              </Protections.Permition>,
            },
            {
              path:'/administrator/configuracoes/documentos/adicionar',
              element: 
              <Protections.Permition permission={permissions?.documentos_criar}>
                  <AAdicionarDocumentos />
              </Protections.Permition>,
            },
            {
              path:'/administrator/configuracoes/documentos/:id',
              element: 
              <Protections.Permition permission={permissions?.documentos_editar}>
                  <AEditarDocumentos />
              </Protections.Permition>,
            },
            {
              path:'/administrator/configuracoes/consentimentos',
              element: 
              <Protections.Permition permission={permissions?.consentimentos_vizualizar}>
                  <AConsentimentos />
              </Protections.Permition>,
            },
            {
              path:'/administrator/configuracoes/consentimentos/adicionar',
              element: 
              <Protections.Permition permission={permissions?.consentimentos_criar}>
                  <AAdicionarConsentimentos />
              </Protections.Permition>,
            },
            {
              path:'/administrator/configuracoes/consentimentos/:id',
              element: 
              <Protections.Permition permission={permissions?.consentimentos_editar}>
                  <AEditarConsentimentos />
              </Protections.Permition>,
            },
          ]    
        }
      ]
    },
    {
      path:'/cliente',
      element:<URoot />,
      children: [
        {
          path:'/cliente',
          element:<Protections.Redirect permission={Object.values(user).length > 0}>
                    <Protections.Permition permission={user?.user?.type === "user"}>
                      <UHome />
                    </Protections.Permition>
                  </Protections.Redirect>
        }
      ]
    }
    ]);
  
    return(<RouterProvider router={router} />)
  };

//cada página será um objeto
    // {
    //     path:"/componentes",
    //     element: <Teste_Componentes /> ,
    //     errorElement: <error404 />
    // },