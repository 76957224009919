import React from "react";

export const InputLabel = ({children, className="", style={}}) => {
    return(
        <label className={`label ${className}`} style={style}>
            {children}
        </label>
    )
}

