import React from 'react';
import { Column } from '../../../components/layouts/columns';
import { Button } from '../../../components/ui/button';
import { Input } from '../../../components/ui/input';
import { Select } from '../../../components/ui/select';


export const PlansFormRoot = ({values, setValues, loading, disabledList}) => {


    return (
        <>     
            <Column.Root>
                <Column.Content>

                    <Input.Root className='mb-5'>
                        <Input.Label className='ml-3 has-text-grey' style={{ marginBottom: 0 }}> Nome </Input.Label>
                        <Input.Contents>
                            <Input.Prompt className='is-rounded' value={values} setValue={setValues} field={'nome'} required={true} disabled={disabledList?.nome}></Input.Prompt>
                        </Input.Contents>
                    </Input.Root>

                    <Input.Root className='mb-5'>
                        <Input.Label className='ml-3 has-text-grey' style={{ marginBottom: 0 }}> Descricao </Input.Label>
                        <Input.Contents>
                            <Input.Prompt className='is-rounded' value={values} setValue={setValues} field={'descricao'} required={true} disabled={disabledList?.descricao}></Input.Prompt>
                        </Input.Contents>
                    </Input.Root>

                    <Column.Root>
                        <Column.Content>
                            <Input.Root className='mb-5'>
                                <Input.Label className='ml-3 has-text-grey' style={{ marginBottom: 0 }}> Valor </Input.Label>
                                <Input.Contents alignIcon={"left"}>
                                    <Input.Icon align='is-left'>R$</Input.Icon>
                                    <Input.Prompt className='is-rounded' value={values} setValue={setValues} field={'value'} required={true} type='number' disabled={disabledList?.value}></Input.Prompt>
                                </Input.Contents>
                            </Input.Root>
                        </Column.Content>
                        
                        <Column.Content>
                            <Input.Root className='mb-5'>
                                <Input.Label className='ml-3 has-text-grey' style={{ marginBottom: 0 }}>Faturamento a cada</Input.Label>
                                <Select.Root>
                                    <Select.Content color='is-grey' className='is-fullwidth is-rounded' value={values} setValue={setValues} field={'type_expired'} disabled={disabledList?.type_expired}>
                                        <Select.Option value={'not_expired'} title={'Não Fatura'} />
                                        <Select.Option value={'15'} title={'15 Dias'} />
                                        <Select.Option value={'30'} title={'30 Dias'} />
                                        <Select.Option value={'90'} title={'60 Dias'} />
                                        <Select.Option value={'180'} title={'90 Dias'} />
                                        <Select.Option value={'365'} title={'365 Dias'} />
                                    </Select.Content>
                                </Select.Root>
                            </Input.Root>
                        </Column.Content>

                    </Column.Root>

                    

                       

                                   

                </Column.Content>
            </Column.Root>
            <Column.Root className='mt-5 mb-6'>
                <Column.Content>
                    <Button.Root align='right'>
                        <Button.Content>
                            <Button.A color='is-light' link={"/administrator/configuracoes/planos"} loading={loading}>
                                Cancelar
                            </Button.A>
                        </Button.Content>
                        <Button.Content>
                            <Button.Bt className="" color='is-success' colorText="has-text-white" type={"submit"} loading={loading}>
                                Salvar
                            </Button.Bt>
                        </Button.Content>
                    </Button.Root>
                </Column.Content>
            </Column.Root>
        </>
    );
};
