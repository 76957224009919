import React from "react";


export const PageSideBar= ({children}) => {
    return(
        <div className="columns">
            <div className="column is-narrow box" style={{ overflowY: "auto", width: 80, position: 'fixed', bottom: -40, top: 80, zIndex: 1, left:-12}}>
                {children}
            </div>
        </div>
    )
}