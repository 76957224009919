import React, { useState } from 'react';
import { useGlobalContext } from '../../../context/ContextGlobal';
import { DateTimeNow } from '../../../funtions/dates';
import { useNavigate, useParams } from 'react-router-dom';
import { Page } from '../../../components/layouts/page';
import { Column } from '../../../components/layouts/columns';
import { Form } from '../../../components/ui/form';
import { TratamentoError } from '../../../funtions/erros';
import { PlansFormRoot } from './_FormPlanos';
import { addDocument, updateDocument } from '../../../firebase/firestore';


export const AEditarPlanos = () => {

    const { id } = useParams();
    const { user, setAlerts, plansContext } = useGlobalContext();
    const [ loading, setLoading ] = useState(false);
    const [ plano, setPlano ] = useState(Object.values(plansContext?.planos || {}).find(plans => plans.id === id));
    const navigate = useNavigate();



    const EditarPlano = async  () => {
        try{
            setLoading(true);
            await updateDocument('planos', id, plano);
            return navigate('/administrator/configuracoes/planos');
        }
        catch(error){
            return setAlerts((prev) => ([...prev, {title:'Error:', msg:TratamentoError(error) , type:'error'}]));
        }
        finally{
            setLoading(false);
        }
    }

    return (
        <Page.Section className='is-fullheight-with-navbar'>
            <Form.Root action={() => {EditarPlano();}}>
                    <Column.Root>
                        <Column.Content>
                            <p className="subtitle is-size-3">Adicionar Plano</p>
                        </Column.Content>
                    </Column.Root>
                    <PlansFormRoot loading={loading} values={plano} setValues={setPlano} disabledList={{}} />
            </Form.Root >
        </Page.Section>
    );
};
