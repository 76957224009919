import React from "react"


export const CardContents = ({children, className='', style={}}) => {

    return (
        <div className={`card-content ${className}`} style={style}>
            {children}
        </div>

    )
}
