import React from "react";


export const TableRoot = ({children, className="", style={}}) =>{

    return(
        <table className={`table ${className}`} style={style}>
            {children}
        </table>
    )
}