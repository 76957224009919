import React from "react";



export const SelectContent = ({children, color="is-light", size=null, style={}, className="", value, setValue, field=null, disabled=null}) => {

    const changeValue = (event) => {
        if(field){
            setValue({...value, [field]:event.target.value})
        }else{
            setValue(event.target.value)
        }
    }

    return(
        <div class={`select ${color} ${size} ${className}`} style={style}>
            <select 
                value={field ? value[field] : value}
                onChange={changeValue}
                disabled={disabled}
                >
                {children}
            </select>
        </div>
    )
}