import React, { useState } from 'react';
import { useGlobalContext } from '../../../context/ContextGlobal';
import { useNavigate, useParams } from 'react-router-dom';
import { Page } from '../../../components/layouts/page';
import { Column } from '../../../components/layouts/columns';
import { Form } from '../../../components/ui/form';
import { TratamentoError } from '../../../funtions/erros';
import { DocsFormRoot } from './_FormDocumentos';
import { updateDocument } from '../../../firebase/firestore';


export const AEditarDocumentos = () => {

    const { id } = useParams();
    const { setAlerts, documentsContext } = useGlobalContext();
    const [ loading, setLoading ] = useState(false);
    const [ documento, setDocumento ] = useState(Object.values(documentsContext?.documentos || {}).find(doc => doc.id === id));
    const navigate = useNavigate();



    const EditarDocumento = async  () => {
        try{
            setLoading(true);
            await updateDocument('documentos', id, documento);
            return navigate('/administrator/configuracoes/documentos');
        }
        catch(error){
            return setAlerts((prev) => ([...prev, {title:'Error:', msg:TratamentoError(error) , type:'error'}]));
        }
        finally{
            setLoading(false);
        }
    }

    return (
        <Page.Section className='is-fullheight-with-navbar'>
            <Form.Root action={() => {EditarDocumento();}}>
                    <Column.Root>
                        <Column.Content>
                            <p className="subtitle is-size-3">Documento</p>
                        </Column.Content>
                    </Column.Root>
                    <DocsFormRoot loading={loading} values={documento} setValues={setDocumento} disabledList={{}} />
            </Form.Root >
        </Page.Section>
    );
};
