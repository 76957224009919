import React, { useEffect, useState, useCallback } from 'react';
import { Icon } from '../../../components/icons';
import { Column } from '../../../components/layouts/columns';
import { Button } from '../../../components/ui/button';
import { Table } from '../../../components/ui/table';
import { deleteDocument, getDocuments } from '../../../firebase/firestore';
import { useGlobalContext } from '../../../context/ContextGlobal';
import { Input } from '../../../components/ui/input';
import { TratamentoError } from '../../../funtions/erros';
import { Page } from '../../../components/layouts/page';


export const AConsentimentos = () => {

    const {user, setAlerts, consentimentosContext, setConsentimentosContext} = useGlobalContext();
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    const consentimentos = consentimentosContext?.consentimentos || [];

    //coleta uma lista de planos
    const getInfos = useCallback(async () => {   
        try {
            setLoading(true);
            const getConsentimento = await getDocuments('consentimentos');
            setConsentimentosContext({'consentimentos':getConsentimento});
        } 
        catch (error) {
            //mostra o alerta de error para o usuario.
            return setAlerts((prev) => ([...prev, {title:'Error:', msg:TratamentoError(error) , type:'error'}]));
        } 
        finally {
            setLoading(false);
        }
    }, [setConsentimentosContext, setAlerts]);

    //deleta o usuario no cliente e se não tiver cadastro em cliente, ele apaga o usuario do autenticador
    const excluirConsentimento = async (id) => {
        try {
            if(user?.user?.permissions?.consentimentos_excluir){
                setLoading(true);
                await deleteDocument('consentimentos', id);
                await getInfos();
                return setAlerts((prev)=>([...prev, {type:'success', title:'Consentimento Excluído', msg: 'Consentimento excluído da sua base de dados'}]))
            }
            else{
                return setAlerts((prev)=>([...prev, {type:'error', title:'Permissão Negada', msg: 'Você não possui permissão para apagar Consentimentos'}]))
            }
        }
        catch (error) {
            return setAlerts((prev) => ([...prev, {title:'Error:', msg:TratamentoError(error) , type:'error'}]));
        } 
        finally {
            setLoading(false);
        }
    }


    // executa quando carrega a pagina e quando é auterado o getUserList
    useEffect(()=>{
        getInfos();
    },[getInfos])


    const ConsentimentosFiltered = search !== ""
    ? Object.values(consentimentos).filter((consent) => {
        const searchString = `${consent.nome}|${consent.descricao}`.toLowerCase();
        return searchString.includes(search.toLocaleLowerCase());
        })
    : Object.values(consentimentos);

    return (
        <>     
        <Page.Root className='is-fullwidth'>
            <Column.Root className='mt-6 pt-5'>
                <Column.Content>
                    <p className="subtitle is-size-3">Consentimentos</p>
                </Column.Content>
                <Column.Content className='has-text-right'>
                    <Button.Root align='right'>
                        <Button.Content>
                            <Button.Bt color='is-dark' colorText="has-text-white" action={()=>{getInfos()}} loading={loading}>
                                <Icon size={15}>sync</Icon>
                            </Button.Bt>
                        </Button.Content>
                        <Button.Content>
                            <Button.A className="" color='is-success' colorText="has-text-white" link={'/administrator/configuracoes/consentimentos/adicionar'} loading={loading}>+</Button.A>
                        </Button.Content>
                    </Button.Root>

                </Column.Content>
            </Column.Root>
            <Column.Root className=''>
                <Column.Content>
                    <Input.Root>
                        <Input.Contents alignIcon={'left'}>
                            <Input.Prompt value={search} setValue={setSearch} placeholder='Persquisar...'></Input.Prompt>
                            <Input.Icon align='is-left'>
                                <Icon size={20}>search</Icon>
                            </Input.Icon>
                        </Input.Contents>
                    </Input.Root>
                </Column.Content>
            </Column.Root>
            <Column.Root className=''>
                <Column.Content>
                <Table.Root className='is-fullwidth is-hoverable is-narrow has-text-centered ' >
                    <Table.Head>
                        <Table.Row >
                            <Table.Cell>Nome</Table.Cell>
                            <Table.Cell>Descrição</Table.Cell>
                            <Table.Cell>Tipo de Assinatura</Table.Cell>
                            <Table.Cell>Ações</Table.Cell>
                        </Table.Row>
                    </Table.Head>
                    <Table.Body>
                        {
                            ConsentimentosFiltered.map((value, index) => {
                                
                                return (
                                    <>
                                    <Table.Row >
                                        <Table.Cell className='is-size-7 is-vcentered'>{value.nome}</Table.Cell>
                                        <Table.Cell className='is-size-7 is-vcentered'>{value.descricao}</Table.Cell>
                                        <Table.Cell className='is-size-7 is-vcentered'>{value.type_assinatura}</Table.Cell>
                                        <Table.Cell className='is-vcentered'>
                                            <div style={{marginBottom:-10, marginTop:5}}>
                                                <Button.Root align='centered'>
                                                    <Button.Content>
                                                        <Button.A size='is-small' color='is-warning' link={`/administrator/configuracoes/consentimentos/${value.id}`} loading={loading}>
                                                            <Icon size={15}>pen</Icon>
                                                        </Button.A>
                                                    </Button.Content>
                                                    <Button.Content>
                                                        <Button.Bt size='is-small' color='is-danger' action={()=>{ excluirConsentimento(value.id); }} loading={loading}>
                                                            <Icon size={15}>trash</Icon>
                                                        </Button.Bt>
                                                    </Button.Content>
                                                </Button.Root>
                                            </div>
                                        </Table.Cell>
                                    </Table.Row>
                                    </>
                                )
                            })
                        }
                    </Table.Body>
                </Table.Root>
                </Column.Content>
            </Column.Root>

        </Page.Root>
        </>
    );
};
