import React from "react";

export const FileRoot = ({children, className="", color="", style={}}) => {
    return(
        <div className={`file is-boxed is-centered ${color} ${className}`} style={style}>
            <label className="file-label">
                {children}
            </label>
        </div>
    )
}