/**
 * Cria um componente de icone. 
 * Site dos icones: https://iconscout.com/unicons/free-line-icon-fonts
 *
 * @param {String} [children=any] - Aceita somente String
 * @param {Number} [size=30] - define o tamanho do icone
 * @param {String} [className=""] - adiciona classe extra do bulma ao icone
 * @returns {JSX.Element} O componente React.
 */

export const Icon = ({children, size=30, className=""}) => {

    switch(children){
        case 'calendar':
            return (<i className={"uil uil-calendar-alt "+className} style={{  fontSize: size }} ></i>)
        
        case 'users':
            return (<i className={"uil uil-users-alt "+className}  style={{  fontSize: size }} ></i>)

        case 'chat-bubble-user':
            return (<i className={"uil uil-chat-bubble-user "+className}  style={{  fontSize: size }} ></i>)

        case 'info':
            return(<i className={"uil uil-file-info-alt "+className} style={{  fontSize: size }}></i>)

        case 'alert':
            return(<i className={"uil uil-exclamation-triangle "+className} style={{  fontSize: size }}></i>)
        
        case 'password':
            return(<i className={"uil uil-key-skeleton "+className} style={{  fontSize: size }}></i> )

        case 'user':
            return(<i className={"uil uil-user "+className} style={{  fontSize: size }}></i> )
        
        case 'arrowLeft':
            return(<i className={"uil uil-angle-left "+className} style={{  fontSize: size }}></i>)
        
        case 'gear':
            return(<i className={"uil uil-setting "+className} style={{  fontSize: size }}></i>)
            
        case 'backspace':
            return(<i className={"uil uil-backspace "+className} style={{  fontSize: size}}></i>)

        case 'suitcase':
            return(<i className={"uil uil-suitcase "+className} style={{  fontSize: size}}></i>)
        
        case 'trash':
            return(<i className={"uil uil-trash-alt "+className} style={{  fontSize: size}}></i>)
        
        case 'pen':
            return(<i className={"uil uil-pen "+className} style={{  fontSize: size}}></i>)
        
        case 'sync':
            return(<i className={"uil uil-sync "+className} style={{  fontSize: size}}></i>)

        case 'search':
            return(<i className={"uil uil-search "+className} style={{  fontSize: size}}></i>)

        case 'lock':
            return(<i className={"uil uil-lock-alt "+className} style={{  fontSize: size}}></i>)

        case 'unlock':
            return(<i className={"uil uil-lock-open-alt "+className} style={{  fontSize: size}}></i>)
                    
        case 'bill':
            return(<i className={"uil uil-bill "+className} style={{  fontSize: size}}></i>)

        case 'selfie':
            return(<i className={"uil uil-selfie "+className} style={{  fontSize: size}}></i>)

        case 'sectors':
            return(<i className={"uil uil-sign-alt "+className} style={{  fontSize: size}}></i>)

        case 'doc':
            return(<i className={"uil uil-files-landscapes-alt "+className} style={{  fontSize: size}}></i>)
    
        case 'file-check':
            return(<i className={"uil uil-file-check-alt "+className} style={{  fontSize: size}}></i>)

        default:
            return(<></>)

    }
}


// site dos icones: https://iconscout.com/unicons/free-line-icon-fonts