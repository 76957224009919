import React, { useState } from 'react';
import { useGlobalContext } from '../../../context/ContextGlobal';
import { DateTimeNow } from '../../../funtions/dates';
import { useNavigate } from 'react-router-dom';
import { UserFormRoot } from './_FormUser';
import { Page } from '../../../components/layouts/page';
import { Column } from '../../../components/layouts/columns';
import { Form } from '../../../components/ui/form';
import { NewUser } from '../../../funtions/requests';
import { TratamentoError } from '../../../funtions/erros';


export const AAdicionarUsuario = () => {

    const { setAlerts, user, userContext } = useGlobalContext();
    const [ userInfos, setUserInfo ] = useState({});
    const setores = userContext?.setores || [];
    const [ loading, setLoading ] = useState(false);
    const navigate = useNavigate();
    const clientId = user.client.id;

    const criarUsuario = async  () => {
        //deixa os botões em formato loading
        try{
            setLoading(true);
            //cria um objeto inicial para o usuario
            let userInfo = {...userInfos, createBy: user.user.nome, dtCreated: DateTimeNow(), type:'admin'};
            await NewUser(userInfo, clientId, setLoading, setAlerts, navigate);
        }
        catch(error){
            return setAlerts((prev) => ([...prev, {title:'Error:', msg:TratamentoError(error) , type:'error'}]));
        }
        finally{
            setLoading(false);
        }
    }

    return (
        <Page.Section className='is-fullheight-with-navbar'>
            <Form.Root action={() => {criarUsuario();}}>
                    <Column.Root>
                        <Column.Content>
                            <p className="subtitle is-size-3">Adicionar Usuário</p>
                        </Column.Content>
                    </Column.Root>
                    <UserFormRoot loading={loading} values={userInfos} setValues={setUserInfo} permissions={setores} disabledList={{}} />
            </Form.Root >
        </Page.Section>
    );
};
