import React, { useEffect, useState, useCallback } from 'react';
import { Icon } from '../../../components/icons';
import { Column } from '../../../components/layouts/columns';
import { Button } from '../../../components/ui/button';
import { Table } from '../../../components/ui/table';
import { getDocument, getSubDocuments, updateDocument } from '../../../firebase/firestore';
import { useGlobalContext } from '../../../context/ContextGlobal';
import { DeleteUser } from '../../../funtions/requests';
import { Input } from '../../../components/ui/input';
import { TratamentoError } from '../../../funtions/erros';
import { Page } from '../../../components/layouts/page';


export const AUsuarios = () => {

    const {user, setAlerts, userContext, setUserContext} = useGlobalContext();
    const users = userContext?.users || {};
    const setores = userContext?.setores || {};
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    const clientId = user.client.id;

    //coleta uma lista de usuarios e de setores
    const getInfos = useCallback(async () => {   
        try {
            setLoading(true);
            const getclient = await getDocument('clientes', clientId);
            const getUsersStatus = getclient.users;
            const getUser = await getSubDocuments('clientes', clientId, 'users');
            const users = Object.values(getUser).map( (u) => {
                    return {...u, status:getUsersStatus[u.id]}
                }
            )
            const getSetores = await getSubDocuments('clientes', clientId, 'setores');
            setUserContext({'users':users, 'setores':getSetores});
        } 
        catch (error) {
            //mostra o alerta de error para o usuario.
            return setAlerts((prev) => ([...prev, {title:'Error:', msg:TratamentoError(error) , type:'error'}]));
        } 
        finally {
            setLoading(false);
        }
    }, [clientId, setUserContext, setAlerts]);

    //deleta o usuario no cliente e se não tiver cadastro em cliente, ele apaga o usuario do autenticador
    const excluirUser = (uid) => {
        try {
            if(user?.user?.permissions?.usuarios_excluir){
                const userInfo = Object.values(users).find(u => u.id === uid);
                return DeleteUser(uid, userInfo, clientId, setLoading, setAlerts, getInfos);
            }else{
                return setAlerts((prev)=>([...prev, {type:'error', title:'Permissão Negada', msg: 'Você não possui permissão para excluir usuarios'}]))
            }
        }
        catch (error) {
            return setAlerts((prev) => ([...prev, {title:'Error:', msg:TratamentoError(error) , type:'error'}]));
        } 
        finally {
            setLoading(false);
        }
    }

    const bloquearDesbloquearUser = async (uid) => {
        try {
            
            if(user?.user?.permissions?.usuarios_bloquear){
                setLoading(true);

                const userInfo = Object.values(users).find(u => u.id === uid);
                
                await updateDocument('clientes', clientId, { [`users.${uid}`]: !userInfo.status });

                if(!userInfo.status){
                    setAlerts(prev => [...prev,{ type: 'success', title: 'Usuário Bloqueado', msg: `Usuário ${userInfo.nome} Bloqueado com sucesso.`},]);
                }
                else{
                    setAlerts(prev => [...prev,{ type: 'success', title: 'Usuário Desbloqueado', msg: `Usuário ${userInfo.nome} Desbloqueado com sucesso.`},]);
                }

                getInfos();

                setLoading(false);
            }else{
                return setAlerts((prev)=>([...prev, {type:'error', title:'Permissão Negada', msg: 'Você não possui permissão para bloquear usuarios'}]))
            }
        
        }
        catch (error) {
            return setAlerts((prev) => ([...prev, {title:'Error:', msg:TratamentoError(error) , type:'error'}]));
        } 
        finally {
            setLoading(false);
        }

    }

    // executa quando carrega a pagina e quando é auterado o getUserList
    useEffect(()=>{
        getInfos();
    },[getInfos])


    const usersFiltered = search !== ""
    ? Object.values(users).filter((user) => {
        const sectorName = setores.find(setor => setor.id === user.sectorId)?.nome || "";
        const status = user?.status ? "Ativo" : "Bloqueado";

        const searchString = `${user.nome}|${user.email}|${user.cargo}|${sectorName}|${status}`.toLowerCase();
        return searchString.includes(search.toLocaleLowerCase());
        })
    : Object.values(users);

    return (
        <>     
        <Page.Root className='is-fullwidth'>
            <Column.Root className='mt-6 pt-5'>
                <Column.Content>
                    <p className="subtitle is-size-3">Usuários</p>
                </Column.Content>
                <Column.Content className='has-text-right'>
                    <Button.Root align='right'>
                        <Button.Content>
                            <Button.Bt color='is-dark' colorText="has-text-white" action={()=>{getInfos()}} loading={loading}>
                                <Icon size={15}>sync</Icon>
                            </Button.Bt>
                        </Button.Content>
                        <Button.Content>
                            <Button.A className="" color='is-success' colorText="has-text-white" link={'/administrator/configuracoes/usuarios/adicionar'} loading={loading}>+</Button.A>
                        </Button.Content>
                    </Button.Root>

                </Column.Content>
            </Column.Root>
            <Column.Root className=''>
                <Column.Content>
                    <Input.Root>
                        <Input.Contents alignIcon={'left'}>
                            <Input.Prompt value={search} setValue={setSearch} placeholder='Persquisar...'></Input.Prompt>
                            <Input.Icon align='is-left'>
                                <Icon size={20}>search</Icon>
                            </Input.Icon>
                        </Input.Contents>
                    </Input.Root>
                </Column.Content>
            </Column.Root>
            <Column.Root className=''>
                <Column.Content>
                <Table.Root className='is-fullwidth is-hoverable is-narrow has-text-centered ' >
                    <Table.Head>
                        <Table.Row >
                            <Table.Cell>Status</Table.Cell>
                            <Table.Cell>Nome</Table.Cell>
                            <Table.Cell>E-mail</Table.Cell>
                            <Table.Cell>Cargo</Table.Cell>
                            <Table.Cell>Setor</Table.Cell>
                            <Table.Cell>Ações</Table.Cell>
                        </Table.Row>
                    </Table.Head>
                    <Table.Body>
                        {
                            usersFiltered.map((value, index) => {
                                
                                return (
                                    <>
                                    <Table.Row >
                                        <Table.Cell className='is-size-7 is-vcentered'>{value.status ? <p className="tag  is-success is-rounded">Ativo</p> : <p className="tag is-danger is-rounded">Bloqueado</p>}</Table.Cell>
                                        <Table.Cell className='is-size-7 is-vcentered'>{value.nome}</Table.Cell>
                                        <Table.Cell className='is-size-7 is-vcentered'>{value.email}</Table.Cell>
                                        <Table.Cell className='is-size-7 is-vcentered'>{value.cargo}</Table.Cell>
                                        <Table.Cell className='is-size-7 is-vcentered'>{setores.find(setor => setor.id === value.sectorId)?.nome}</Table.Cell>
                                        <Table.Cell className='is-vcentered'>
                                            <div style={{marginBottom:-10, marginTop:5}}>
                                                <Button.Root align='centered'>
                                                    <Button.Content>
                                                        <Button.A size='is-small' color='is-warning' link={`/administrator/configuracoes/usuarios/${value.id}`} loading={loading}>
                                                            <Icon size={15}>pen</Icon>
                                                        </Button.A>
                                                    </Button.Content>
                                                    <Button.Content>
                                                        { value.status 
                                                            ?   <Button.Bt size='is-small' color='is-danger' action={()=>{bloquearDesbloquearUser(value.id)}} loading={loading}>
                                                                    <Icon size={15}>lock</Icon>
                                                                </Button.Bt>
                                                            :   <Button.Bt size='is-small' color='is-success'  action={()=>{bloquearDesbloquearUser(value.id)}}  loading={loading}>
                                                                    <Icon size={15}>unlock</Icon>
                                                                </Button.Bt>

                                                        }
                                                       
                                                    </Button.Content>
                                                    <Button.Content>
                                                        <Button.Bt size='is-small' color='is-danger' action={()=>{ excluirUser(value.id); }} loading={loading}>
                                                            <Icon size={15}>trash</Icon>
                                                        </Button.Bt>
                                                    </Button.Content>
                                                </Button.Root>
                                            </div>
                                        </Table.Cell>
                                    </Table.Row>
                                    </>
                                )
                            })
                        }
                    </Table.Body>
                </Table.Root>
                </Column.Content>
            </Column.Root>

        </Page.Root>
        </>
    );
};
