
/**
 * Função para retornar o time atual neste formato: "day/month/year hours:minutes" 
 * 
 * @returns {string} - Time Atual"day/month/year hours:minutes".
 */

export const DateTimeNow = () => {
    const now = new Date();
    
    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Os meses são baseados em zero
    const year = String(now.getFullYear()).slice(-2); // Pega os últimos dois dígitos do ano
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}`;
}