import React, { useState } from 'react';
import { useGlobalContext } from '../../context/ContextGlobal';
import { Input } from '../../components/ui/input';
import img_illutrator from "../../assets/illustration_events.png"
import { Icon } from '../../components/icons';
import { Button } from'../../components/ui/button';
import { logIn, resetPassword } from '../../firebase/auth';
import { getDocumentsWithQuery, getDocument, getSubDocument } from '../../firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { Column } from '../../components/layouts/columns/index';
import { Card } from '../../components/ui/card';


export const Login = () => {

    const {setUser, setAlerts, alerts, setErrors} = useGlobalContext();
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [senha, setSenha] = useState('');
    const [loading, setLoading] = useState(false);

    const logar = async () => {
        if( email !== '' && senha !== ''){
            setLoading(true);
            const auth = await logIn(email, senha);
            if('error' in auth){
                setAlerts([...alerts, {'type':'success', 'title':'Logado', 'msg':  JSON.stringify(auth)}])
                switch(auth.error.code){
                    case "auth/invalid-credential":
                        return setAlerts([...alerts, {'type':'error', 'title':"E-mail ou Senha incorretos", 'msg': "Verifique seu e-mail ou senha, e tente novamente" }])
                    case "auth/too-many-requests":
                        return setAlerts([...alerts, {'type':'error', 'title':"Usuário Temporariamente Bloqueado", 'msg': "Aguarde 5 minutos e tente novamente." }]) 
                    default:
                        return setAlerts([...alerts, {'type':'error', 'title':'Error:', 'msg':  JSON.stringify(auth)}]) 
                }
            }
            else{
                const uid = auth.success.uid;
                //realiza uma busca em todos os cliente para saber se esta cadastrado
                const queryUsersTrue = await getDocumentsWithQuery('clientes', 'users.'+uid, '==', true, setErrors);
                const queryUsersFalse = await getDocumentsWithQuery('clientes', 'users.'+uid, '==', false, setErrors);

                const queryTotal = {...queryUsersTrue, ...queryUsersFalse}
                const response  = Object.values(queryTotal)

                if(response.length > 0){
                    //coleta os dados do cliente, retorna um array para um caso de um e-mail possuir mais de um cliente
                    const clientDocs = await Promise.all(
                        response.map(async (client) => {
                            const response = await getDocument('clientes', client.id);
                            return response;
                        })
                    );
                    //coleta os dados do usuário dentro dos clientes
                    const userDatas = await Promise.all(
                        clientDocs.map(async (client) => {
                            const getStatusUser = await getSubDocument('clientes', client.id, 'users', uid);
                            const getSector = await getSubDocument('clientes',client.id, 'setores', getStatusUser?.sectorId);
                            return {client:client, user:{...getStatusUser, status:client.users[uid], permissions: getSector?.permissions}}; // se estiver tudo certo, ele retorna um array com os dados do usuario e de cada empresa.
                        })
                    );

                    //SetItemSession({key:'user', value:userDatas});
                    setUser(userDatas);
                    

                    //se o e-mail ainda não foi verificado, ele realiza uma verificação pelo authentidador.
                    if(!auth.success.emailVerified){
                        const sendEmail = resetPassword(email);
                        setLoading(false);
                        if('success' in sendEmail){
                            return navigate(`/primeiroacesso/${email}`);
                        }else{
                            return setAlerts([...alerts, {'type':'error', 'title':'Error:', 'msg':  JSON.stringify(sendEmail.error)}])
                        }
                        
                    }
                    else{ //caso esteja tudo certo, segue para a tela de seleção de empresas.
                        setLoading(false);
                        return navigate('/selectuser');
                    }

                    
                }  
                else{
                    return setAlerts([...alerts, {'type':'error', 'title':'Usuário não cadastrado', 'msg': 'Contate um administrador ou o suporte da keap.'}])
                }
            }
            
        }
        else{
            return setAlerts([...alerts, {'type':'error', 'title':'Campo Faltantes', 'msg':'Você não preencheu o campo'}])
        }
    }

    return ( 
        <>
            <Card.Root>
                <Card.Content>
                    <Column.Root className='is-vcentered'>
                        <Column.Content className='has-text-centered' size='is-half'>
                            <img src={img_illutrator} style={{ maxHeight: 300 }} alt='teste' />
                        </Column.Content>
                        <Column.Content className='mt-6'>
                            <h1 className='subtitle is-size-2'>KEAP STAFF</h1>
                            <div className="my-5">
                                <Input.Root>
                                    <Input.Contents alignIcon={'left'}>
                                        <Input.Prompt className='is-rounded' placeholder='E-mail' type='email' value={email} setValue={setEmail} />
                                        <Input.Icon>
                                            <Icon size={20}>user</Icon>
                                        </Input.Icon>
                                    </Input.Contents>
                                </Input.Root>
                            </div>
                            <div className="my-5">
                                <Input.Root>
                                    <Input.Contents alignIcon={'left'} >
                                        <Input.Prompt className='is-rounded' placeholder='Senha' type='password' value={senha} setValue={setSenha} />
                                        <Input.Icon>
                                            <Icon size={20}>password</Icon>
                                        </Input.Icon>
                                    </Input.Contents>
                                </Input.Root>
                            </div>
                            <div className="my-5 mt-6 ">
                                <Button.Bt className="is-rounded has-background-link-light is-fullwidth " colorText="has-text-link-dark" action={() => { logar(); }} loading={loading}>Entrar</Button.Bt>
                            </div>
                            <div className="my-5">
                                <Button.A className="is-rounded is-fullwidth is-outlined" color='is-ligth' colorText="has-text-link-dark" link={'/esquecisenha'}  loading={loading}>Esqueci minha Senha</Button.A>
                            </div>
                        </Column.Content>
                    </Column.Root>

                    <Column.Root>
                        <Column.Content>
                            <div className="columns is-mobile">
                                <div className="column">
                                    <Button.Bt className="is-rounded is-fullwidth " color='is-ligth'  loading={loading}>Quero Contratar</Button.Bt>
                                </div>
                                <div className="column">
                                    <Button.Bt className="is-rounded is-fullwidth" color='is-ligth'  loading={loading}>Quero Trabalhar</Button.Bt>
                                </div>
                            </div>
                        </Column.Content>
                        <Column.Content></Column.Content>
                    </Column.Root>
                </Card.Content>
            </Card.Root>
        </> 
    );
};
