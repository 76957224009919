import React from "react"

export const CardRoot = ({children, className="", style={}}) => {

    return (
        <div className={`card ${className}`} style={style}>
         {children}
        </div>
    )
}
   
