import React, { useState } from 'react';
import { updateSubDocument} from '../../../firebase/firestore';
import { useGlobalContext } from '../../../context/ContextGlobal';
import { useNavigate, useParams } from 'react-router-dom';
import { SetorFormRoot } from './_FormSetor';
import { Page } from '../../../components/layouts/page';
import { Column } from '../../../components/layouts/columns';
import { Form } from '../../../components/ui/form';
import { TratamentoError } from '../../../funtions/erros';

export const AEditarSetor = () => {

    const {id} = useParams();
    const { setAlerts, user, setoresContext} = useGlobalContext();
    const [setor, setSetor] = useState(Object.values(setoresContext?.setores || {}).find(sector => sector.id === id));
    const [permissions, setPermissions] = useState(setor?.permissions || {});
    const permissionsGlobais = setoresContext?.globalPermissions || {};
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const clientId = user.client.id;


    const editarSetor = async  () => {
        try{
            setLoading(true);
            const new_setor = {...setor, 'permissions':{...permissions}}
            await updateSubDocument('clientes', clientId, 'setores', id, new_setor);
    
            setAlerts((prev)=>([...prev, {type:'success', title:'Setor Editado', msg: `O Setor "${new_setor.nome}" foi salvo com sucesso.`}]))
            navigate('/administrator/configuracoes/setores');
        }catch(error){
            return setAlerts((prev) => ([...prev, {title:'Error:', msg:TratamentoError(error) , type:'error'}]));
        }finally{
            setLoading(false);
        }
    }

    return (
        <>
            <Form.Root className='mt-6 is-fullwidth' action={() => { editarSetor(); }}>
                <Page.Root>
                    <Column.Root>
                        <Column.Content>
                            <p className="subtitle is-size-3">Adicionar Setor</p>
                        </Column.Content>
                    </Column.Root>
                    <Page.Content>
                        <SetorFormRoot loading={loading} values={setor} permitions={permissions} setPermissions={setPermissions} setValues={setSetor} permissionsList={permissionsGlobais}></SetorFormRoot>
                    </Page.Content>
                </Page.Root>
            </Form.Root>
        </>
    );

};
