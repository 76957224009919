import React from "react";




export const InputRoot = ({children, className="", style={}}) => {

    return(
        <div className={`field ${className}`} style={style}>
            {children}
        </div>
    )
}

