import React from "react";
import { DateTimeNow } from "../../../funtions/dates";

export const AlertMessage = ({title, text, type, close=()=>{}}) => {


    let color;

    switch(type){
        case 'error':
            color='is-danger';
            break;
        case 'success':
            color='is-success';
            break;
        default:
            color='is-light';
            break;
    }

    return(

        <article className={`message mb-3 ${color} has-background-white`}>
           
            <div className="message-body">
                <div style={{position:'absolute', right:25}}>
                    <button className="delete"  onClick={()=>{ close() }}/>
                </div>
                <strong>{title}</strong>
                <br />
                {text}
                <br />
                <div className="has-text-right is-size-7">
                  {DateTimeNow()}  
                </div>  

            </div>

            <progress className={`progress ${color}`} style={{height:5, marginTop:-5}}/>
        </article>
                      
    )
}