import React from 'react';
import { Page } from '../../../components/layouts/page';
import { SideBar } from '../../../components/layouts/sidebar';
import { Icon } from '../../../components/icons';
import { Outlet } from 'react-router-dom';

export const AConfiguracoes = () => {

    return (
        <>     
            <Page.Content className="" style={{marginTop: 80, marginLeft: 80, marginRight: 10, marginBottom: 500}}>
                <Outlet/>
            </Page.Content> 

            <Page.SideBar>
                <SideBar.Root>
                    <SideBar.List>
                        <SideBar.Item title={"Informações"} link={"/administrator/configuracoes"}>
                            <Icon size={30}>info</Icon>
                        </SideBar.Item>
                        <SideBar.Item title={"Usuarios"} link={"/administrator/configuracoes/usuarios"}>
                            <Icon size={30}>users</Icon>
                        </SideBar.Item>
                        <SideBar.Item title={"Setores"} link={"/administrator/configuracoes/setores"}>
                            <Icon size={30}>sectors</Icon>
                        </SideBar.Item>
                        <SideBar.Item title={"Planos"} link={"/administrator/configuracoes/planos"}>
                            <Icon size={30}>bill</Icon>
                        </SideBar.Item>
                        <SideBar.Item title={"Documentos"} link={"/administrator/configuracoes/documentos"}>
                            <Icon size={30}>doc</Icon>
                        </SideBar.Item>
                        <SideBar.Item title={"Consentimentos"} link={"/administrator/configuracoes/consentimentos"}>
                            <Icon size={30}>file-check</Icon>
                        </SideBar.Item>
                    </SideBar.List>
                </SideBar.Root>
            </Page.SideBar>
        </>
    );
};
