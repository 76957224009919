import React from "react";


export const TableCell = ({children, className="", style={}, size=null, borderWidth=null, onClick=()=>{}}) =>{

    return(
        <td className={className} style={{...style, width: size, borderWidth: borderWidth}} onClick={()=>{onClick()}}>
            {children}
        </td>
    )
}