import React from "react";


export const TableHead = ({children}) =>{

    return(
        <thead>
            {children}
        </thead>
    )
}