import { SelectContent } from "./SelectContent";
import { SelectIcon } from "./SelectIcon";
import { SelectOption } from "./SelectOption";
import { SelectRoot } from "./SelectRoot";

/**
 * # Root
 * Cria um componente para acomodar os componentes do o Select.
 *
 * @param {React.ReactNode} children - Aceita qualquer componente react.
 * @returns {JSX.Element} O componente React.
 * 
 * # Content
 * Cria um componente do "SELECT".
 *
 * @param {React.ReactNode} children - Aceita String que parecerá dentro do botão, ou qualquer componente react.
 * @param {string} className - Recebe uma classe qualquer classe do bulma.
 * @param {string} color - Recebe uma classe de cor de botão do bulma ("is-white", "is-black", "is-light", "is-dark", "is-primary", "is-link", "is-info", "is-success", "is-warning", "is-danger")
 * @param {string} size - Recebe uma classe de tamanho de botão do bulma  ("is-large", "is-medium", "is-small")
 * @param {boolean} value - Aceita uma variavel que será checada armazada true e false caso CHECADO ou NÃO CHECADO
 * @param {setBoolean} setValue - Aceita varial para trabalhar setar valores no VALUE
 * @returns {JSX.Element} O componente React.
 * 
 * # Icon
 * Cria um componente de ICONE para o SELECT.
 *
 * @param {React.ReactNode} children - Aceita String que parecerá dentro do botão, ou qualquer componente react.
 * @param {string} align - Recebe uma classe de alinhamento do bulma ("is-left", "is-right").
 * @returns {JSX.Element} O componente React.
 * 
 * # Option
 * Cria um componente das Opções do SELECT.
 *
 * @param {string} currentValue - Recebe o valor atual do select para saber se deve ser selecionado.
 * @param {string} value - Recebe uma string como valor.
 * @param {string} title - Recebe uma string que irá mostra para o usuário.
 * @returns {JSX.Element} O componente React.
 * 
 * 
 */

export const Select = {
    Root:SelectRoot,
    Content:SelectContent,
    Icon: SelectIcon,
    Option:SelectOption,
}


//Exemplo de utilização:

// <Select.Root>
//     <Select.Content color="danger" size="small" value={valor} onChange={handleSelectChange}>
//         <Select.Option value='valor 1' title='Valor 1'></Select.Option>
//         <Select.Option value='valor 2' title='Valor 2'></Select.Option>
//     </Select.Content>
//     <Select.Icon>
//         X
//     </Select.Icon>
// </Select.Root>
