import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyDX0hul8Q-Mlu2fRaiNeJ7FsPwrHXtagEU",
    authDomain: "keap-staff.firebaseapp.com",
    projectId: "keap-staff",
    storageBucket: "keap-staff.appspot.com",
    messagingSenderId: "387743147753",
    appId: "1:387743147753:web:a37a02e17d17ad55e7b278"
  };


export const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);

export const auth = getAuth(app);

export const functions = getFunctions(app);

export const storage = getStorage(app);