import { db } from "."; 
import { collection, getDocs, doc, getDoc, addDoc, setDoc, updateDoc, deleteDoc, onSnapshot, query, where, deleteField } from "firebase/firestore";


/**
 * Coleta todos os documentos da coleção
 * 
 * @param {String} collectionName - Recebe o nome da coleção.
 * @returns {Object} - Retorna um objecto com os dados da coleção
 */
export const getDocuments = async (collectionName) => {
    try {
        const collectionRef = collection(db, collectionName);
        const dataSnap = await getDocs(collectionRef);
        return dataSnap.docs.map(data => ({ id: data.id, ...data.data() }));
    } catch (error) {
        throw new Error(error.message);
    }
};

/**
 * Coleta todos os documentos de uma subcoleção
 * 
 * @param {String} collectionName - Recebe o nome da coleção.
 * @param {String} documentId - Recebe o nome do documento.
 * @param {String} SubCollectionName - Recebe o nome da subcoleção.
 * @returns {Object} - Retorna um objecto com os dados da coleção
 */
export const getSubDocuments = async (collectionName, documentId, SubCollectionName) => {
    try {
        const collectionRef = collection(db, collectionName, documentId, SubCollectionName);
        const dataSnap = await getDocs(collectionRef);
        return dataSnap.docs.map(data => ({ id: data.id, ...data.data() }));
    } catch (error) {
        throw new Error(error.message);
    }
};

/**
 * Coleta um documento específico
 * 
 * @param {String} collectionName - Recebe o nome da coleção.
 * @param {String} documentId - Recebe o nome do documento.
 * @returns {Object} - Retorna um objecto com os dados da coleção
 */
export const getDocument = async (collectionName, documentId) => {
    try {
        const docRef = doc(db, collectionName, documentId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            return { id: docSnap.id, ...docSnap.data() };
        } else {
            throw new Error(`Nenhum documento Encontrando em ${collectionName}>>>${documentId}`);
        }
    } catch (error) {
        throw new Error(error.message);
    }
};

/**
 * Coleta um Subdocumento específico
 * 
 * @param {String} collectionName - Recebe o nome da coleção.
 * @param {String} documentId - Recebe o nome do documento.
 * @param {String} SubCollectionName - Recebe o nome da subcoleção.
 * @param {String} SubDocumentId - Recebe o nome do subdocumento.
 * @returns {Object} - Retorna um objecto com os dados da coleção
 */
export const getSubDocument = async (collectionName, documentId, SubCollectionName, SubDocumentId) => {
    try {
        const docRef = doc(db, collectionName, documentId, SubCollectionName, SubDocumentId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            return { id: docSnap.id, ...docSnap.data() };
        } else {
            throw new Error(`Nenhum documento Encontrando em ${collectionName}>>>${documentId}>>>${SubCollectionName}>>>${SubDocumentId}`);
        }
    } catch (error) {
        throw new Error(error.message);
    }
};

/**
 * Adiciona um documento com ID aleatório
 * 
 * @param {String} collectionName - Recebe o nome da coleção.
 * @param {Object} values - Recebe o objeto de valores.
 * @returns {Object} - Retorna um objecto com os dados da coleção
 */
export const addDocument = async (collectionName, values) => {
    try {
        const docRef = await addDoc(collection(db, collectionName), values);
        return docRef.id;
    } catch (error) {
        throw new Error(error.message);
    }
};


/**
 * Adiciona um subDocumento com ID aleatório
 * 
 * @param {String} collectionName - Recebe o nome da coleção.
 * @param {Object} values - Recebe o objeto de valores.
 * @returns {Object} - Retorna um objecto com os dados da coleção
 */
export const addSubDocument = async (collectionName, documentId, subCollecionName, values) => {
    try {
        const docRef = await addDoc(collection(db, collectionName, documentId, subCollecionName), values);
        return docRef.id;
    } catch (error) {
        throw new Error(error.message);
    }
};

/**
 * Adiciona um documento passando um ID especifico
 * 
 * @param {String} collectionName - Recebe o nome da coleção.
 * @param {String} documentId - Recebe o nome do documento.
 * @param {Object} values - Recebe o objeto de valores.
 * @returns {Object} - Retorna um objecto com os dados da coleção
 */
export const setDocument = async (collectionName, documentId, values) => {
    try {
        const docRef = doc(db, collectionName, documentId);
        await setDoc(docRef, values);
        return documentId;
    } catch (error) {
        throw new Error(error.message);
    }
};


/**
 * Adiciona um documento passando um ID especifico
 * 
 * @param {String} collectionName - Recebe o nome da coleção.
 * @param {String} documentId - Recebe o nome do documento.
 * @param {Object} values - Recebe o objeto de valores.
 * @returns {Object} - Retorna um objecto com os dados da coleção
 */
export const setSubDocument = async (collectionName, documentId, subCollectionName, subDocument, values) => {
    try {
        const docRef = doc(db, collectionName, documentId, subCollectionName, subDocument);
        await setDoc(docRef, values);
        return documentId;
    } catch (error) {
        throw new Error(error.message);
    }
};

/**
 * Atualiza o documento passando o ID e os valores que deve atualizar
 * 
 * @param {String} collectionName - Recebe o nome da coleção.
 * @param {String} documentId - Recebe o nome do documento.
 * @param {Object} values - Recebe o objeto de valores.
 * @returns {Object} - Retorna um objecto com os dados da coleção
 */
export const updateDocument = async (collectionName, documentId, values) => {
    try {
        const docRef = doc(db, collectionName, documentId);
        await updateDoc(docRef, values);
        return documentId;
    } catch (error) {
        throw new Error(error.message);
    }
};

/**
 * Atualiza o SubDocumento passando o ID e os valores que deve atualizar
 * 
 * @param {String} collectionName - Recebe o nome da coleção.
 * @param {String} documentId - Recebe o nome do documento.
 * @param {Object} values - Recebe o objeto de valores.
 * @returns {Object} - Retorna um objecto com os dados da coleção
 */
export const updateSubDocument = async (collectionName, documentId, subCollectionName, subDocumentId, values) => {
    try {
        const docRef = doc(db, collectionName, documentId, subCollectionName, subDocumentId);
        await updateDoc(docRef, values);
        return documentId;
    } catch (error) {
        throw new Error(error.message);
    }
};

/**
 * Deleta o documento com base no ID especificado
 * 
 * @param {String} collectionName - Recebe o nome da coleção.
 * @param {String} documentId - Recebe o nome do documento.
 * @returns {Object} - Retorna um objecto com os dados da coleção
 */
export const deleteDocument = async (collectionName, documentId) => {
    try {
        const docRef = doc(db, collectionName, documentId);
        await deleteDoc(docRef);
        return documentId;
    } catch (error) {
        throw new Error(error.message);
    }
};

/**
 * Deleta o subDocumento com base no ID especificado
 * 
 * @param {String} collectionName - Recebe o nome da coleção.
 * @param {String} documentId - Recebe o nome do documento.
 * @returns {Object} - Retorna um objecto com os dados da coleção
 */
export const deleteSubDocument = async (collectionName, documentId, subCollectionName, SubDocumentId) => {
    try {
        const docRef = doc(db, collectionName, documentId, subCollectionName, SubDocumentId);
        await deleteDoc(docRef);
    } catch (error) {
        throw new Error(error.message);
    }
};

/**
 * Deleta um campo especifico do documento.
 * 
 * @param {String} collectionName - Recebe o nome da coleção.
 * @param {String} documentId - Recebe o nome do documento.
 * @param {String} field - Recebe o campo que deve ser deletado  | users.uid  .
 * @returns {Object} - Retorna um objecto com os dados da coleção
 */
export const deleteDocumentField = async (collectionName, documentId, field) => {
    try {
        const docRef = doc(db, collectionName, documentId);
        await updateDoc(docRef, {
            [`${field}`]: deleteField()
        })
    } catch (error) {
        throw new Error(error.message);
    }
};




/**
 * Função para escutar atualizações em tempo real de uma coleção
 * 
 * @param {String} collectionName - Recebe o nome da coleção.
 * @param {String} documentId - Recebe o nome do documento.
 * @param {SetValue} callback - Recebe uma variavel para setar os valores atualizados.
 * @returns {Object} - Retorna um objecto com os dados da coleção
 */
export const getDocumentsRealtime = (collectionName, callback) => {
    const colRef = collection(db, collectionName);
    return onSnapshot(colRef, (snapshot) => {
        const dataSnap = snapshot.docs.map(data => ({ id: data.id, ...data.data() }));
        callback(dataSnap);
    }, (error) => {
        console.error("Error get documents in real-time:", error);
    });
};


/**
 * Função para escutar atualizações em tempo real de um documento específico
 * 
 * @param {String} collectionName - Recebe o nome da coleção.
 * @param {String} documentId - Recebe o nome do documento.
 * @param {SetValue} callback - Recebe uma variavel para setar os valores atualizados.
 * @returns {Object} - Retorna um objecto com os dados da coleção
 */
export const getDocumentRealtime = (collectionName, documentId, callback) => {
    const docRef = doc(db, collectionName, documentId);
    return onSnapshot(docRef, (docSnapshot) => {
        const dataSnap = { id: docSnapshot.id, ...docSnapshot.data() };
        callback(dataSnap);
    }, (error) => {
        throw new Error(error.message);
    });
};

/**
 * Função para realizar uma consulta de documentos com filtro
 * 
 * @param {String} collectionName - Recebe o nome da coleção.
 * @param {String} field - Recebe o nome de um campo do documento a ser pesquisado.
 * @param {String} operator - Recebe um tipo de operador ("==", ">", "<", ">=", "<=", "!=").
 * @param {string} values - Recebe um valor a ser pesquisado.
 * @returns {Object} - Retorna um objecto com os dados da coleção
 */
export const getDocumentsWithQuery = async (collectionName, field, operator, value) => {
    try {
        const q = query(collection(db, collectionName), where(field, operator, value));
        const querySnapshot = await getDocs(q);
        const data = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        return data;
    } catch (error) {
        throw new Error(error);
    }
};

/**
 * Função para realizar uma consulta de subDocumentos com filtro
 * 
 * @param {String} collectionName - Recebe o nome da coleção.
 * @param {String} field - Recebe o nome de um campo do documento a ser pesquisado.
 * @param {String} operator - Recebe um tipo de operador ("==", ">", "<", ">=", "<=", "!=").
 * @param {string} values - Recebe um valor a ser pesquisado.
 * @returns {Object} - Retorna um objecto com os dados da coleção
 */
export const getSubDocumentsWithQuery = async (collectionName, documentId, SubcollectionName, field, operator, value) => {
    try {
        const q = query(collection(db, collectionName, documentId, SubcollectionName), where(field, operator, value));
        const querySnapshot = await getDocs(q);
        const data = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        return data;
    } catch (error) {
        throw new Error(error);
    }
};