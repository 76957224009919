
import HandlingError from "./handlingError"
import { NotPermition } from "./notPermition"

/** 
 * # NotPermition
 * Cria um componente mostrando que o usuário não possui permissão.
 *
 * @returns {JSX.Element} O componente React.
 */


export const Error = {
    Permition: NotPermition,
    Handling: HandlingError,
}
