import React from "react";

export const FormRoot = ({children, className="", style={}, action=()=>{}}) => {

    const handleSubmit = (e) => {
        e.preventDefault(); // Previne o comportamento padrão de recarregar a página
        action();
      };

    return(
        <form className={className} style={style} onSubmit={handleSubmit}>
            {children}
        </form>
    )
}

