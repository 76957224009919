import React, { useState } from 'react';
import { Page } from '../../../components/layouts/page';
import { SideBar } from '../../../components/layouts/sidebar';
import { Icon } from '../../../components/icons';
import { useGlobalContext } from '../../../context/ContextGlobal';

export const UHome = () => {

    const [active, setActive] = useState(false);
    const {user} = useGlobalContext();

    return (
            <>            
            <Page.Root>
                <Page.SideBar>
                    <SideBar.Root>
                        <SideBar.List>
                            <SideBar.Item title={"Informações"} link={"/eventos/informações"}>
                                <Icon size={30}>info</Icon>
                            </SideBar.Item>
                            <SideBar.Item title={"Colaboradores"} link={"/"}>
                                <Icon size={30}>users</Icon>
                            </SideBar.Item>
                        </SideBar.List>
                    </SideBar.Root>
                </Page.SideBar>

                <Page.Content>
                    estou no caminho do usuário
                </Page.Content>
            </Page.Root>
            </>
    );
};
