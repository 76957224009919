import React from "react";
import { Link } from "react-router-dom";
import { useGlobalContext } from "../../../context/ContextGlobal";



export const NavbarItemNoClicacked = ({children, colorBackgroud=null , colorText=null}) => {

    return(
      <label className={`${colorBackgroud} ${colorText} mt-2`}>
        <div class="field has-addons">
          {children}
        </div>
        {/* <div class="container is-flex is-flex-direction-row is-justify-content-center">
         
        </div> */}
      </label>
    )
}