import { register } from "../firebase/auth";
import { deleteSubDocument, deleteDocumentField, updateDocument, setSubDocument, getDocumentsWithQuery, updateSubDocument } from "../firebase/firestore";
import { DeleteUserAuthenticatorFromUid, GetUserAuthenticatorFromEmail } from "../firebase/functions";
import { deleteFile, getPathFile, uploadFile } from "../firebase/storage";



export const DeleteUser = async (uid, userInfo, clientId, setLoading, setAlerts, updateFuntion) => {

    try{
        setLoading(true);

        //deleta a foto de perfil
        if (userInfo) {
            //console.log(userInfo);
            if (userInfo.hasOwnProperty('url_foto')) {
                const path = getPathFile(userInfo.url_foto);
                await deleteFile(path);
            } 
        }
    
        // Deleta o usuário da base de dados do cliente
        await deleteSubDocument('clientes', clientId, 'users', uid);
        await deleteDocumentField('clientes', clientId, `users.${uid}`);
    
        //procura se o usuario esta cadastro em outro cliente
        const queryUsersTrue = await getDocumentsWithQuery('clientes', 'users.'+uid, '==', true);
        const queryUsersFalse = await getDocumentsWithQuery('clientes', 'users.'+uid, '==', false);
        
        const queryTotal = {...queryUsersTrue, ...queryUsersFalse}
        let response  = Object.values(queryTotal) || [];
    
        // Deleta o autenticador do usuario se ele não estiver na base de dados de clientes
        if(response.length === 0){
            try{
                await DeleteUserAuthenticatorFromUid(uid);
            }
            catch(error){
                throw new Error(error.message);
            }
            
        }
    
        setAlerts(prev => [...prev,{ type: 'success', title: 'Usuário deletado', msg: `Usuário deletado da sua base de dados`},]);
    
        updateFuntion();
    
        setLoading(false);
    
        return;

    }
    catch(error){
        throw new Error(error.message);
    }

    
}


export const NewUser = async  (userInfo, clientID, setLoading, setAlerts, navigate) => {
    try{
        let response;

        //tenta realizar o registro do usuário no authenticador
        response = await register(userInfo.email, 'keap123');
    
        //se o usuário ja possui cadastro no autenticador, ele coleta o UID;
        if(response.error){
            response = await GetUserAuthenticatorFromEmail(userInfo.email);
            //caso ele não consiga criar ou achar o usuário, ele para e mostra o erro na tela;
            if(response.error){
                setAlerts(prev => [...prev,{ type: 'error', title: 'Registro de usuário', msg: `Usuário não encontrado e não pode ser criado: ${response.error}`,},]);
                return;
            }
        }
    
        //caso possua uma imagem para fazer upload
        if(Object.keys(userInfo).includes('url_foto')){
            const pathUploadFile = `users/${clientID}/foto_${response.uid}`
            const url_foto = await uploadFile(userInfo['url_foto'], pathUploadFile);
            userInfo = ({...userInfo, 'url_foto':url_foto});
        }
    
        //adiciona o uid no map "users" do cliente e na SubCollection "users"
        updateDocument('clientes',`${clientID}`, { [`users.${response.uid}`]: true });
        setSubDocument('clientes',`${clientID}`, 'users', response.uid, userInfo);
    
        //volta pra pagina inicial de usuarios;
        setLoading(false);
        setAlerts(prev => [...prev,{ type: 'success', title: 'Usuário Criado', msg: `Novo usuário criado com a senha "keap123"`,},]);
        return navigate('/administrator/configuracoes/usuarios');
    }
    catch(error){
        throw new Error(error.message);
    }

}


export const EditUser = async  (userInfo, clientID, setLoading, setAlerts, navigate) => {

    try{
        //caso possua uma imagem para fazer upload
        if(Object.keys(userInfo).includes('url_foto')){
            if(!String(userInfo.url_foto).includes('https://')){
                const pathUploadFile = `users/${clientID}/foto_${userInfo.uid}`
                const url_foto = await uploadFile(userInfo['url_foto'], pathUploadFile);
                userInfo = ({...userInfo, 'url_foto':url_foto});
            }
        }
    
        updateSubDocument('clientes', clientID, 'users', userInfo.uid, userInfo);
    
        //volta pra pagina inicial de usuarios;
        setLoading(false);
        setAlerts(prev => [...prev,{ type: 'success', title: 'Usuário Atualizado', msg: `O usuário ${userInfo.nome} foi atualizado com sucesso!`},]);
        return navigate('/administrator/configuracoes/usuarios');
    }
    catch(error){
        throw new Error(error.message);
    }

    
}