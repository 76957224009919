import React from "react";


export const PaginationRoot = ({children, className="", size="is-small", align="is-centered"}) => {

    return (
        <nav className={`pagination ${className} ${size} ${align}`} role="navigation" aria-label="pagination">
            {children}
        </nav>
    )
}