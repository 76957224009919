import React, { useEffect, useCallback } from 'react';
import { Alert } from '.';



export const AlertFunctional = ({ values, setValues }) => {
  // values deve ser sempre um array de objetos assim: 

  const deletarPrimeiroValor = useCallback(() => {
    setValues(prevAlerts => {
      if (prevAlerts.length > 0) {
        return prevAlerts.slice(1); // Cria um novo array sem o primeiro elemento
      }
      return prevAlerts;
    });
  }, [setValues]);

  const deletaMessage = (key) => {
    setValues(prevAlerts => {
      if (prevAlerts.length > 0) {
        let newArray = [
          ...prevAlerts.slice(0, key), // Elementos antes do índice
          ...prevAlerts.slice(key + 1) // Elementos depois do índice
        ];
        return newArray
      }
      return prevAlerts;
    });
  };

  useEffect(() => {
    // Deleta o primeiro valor do array após 5 segundos
    const esperar = 5000;
    const timer = setTimeout(() => {
      deletarPrimeiroValor();
    }, esperar);

    // Cleanup function para evitar múltiplos timers
    return () => clearTimeout(timer);
  }, [values, deletarPrimeiroValor]);

  return (
    values.length > 0 && (
      <Alert.Root>
        {values.map((alerta, index) => (
          <Alert.Message
            key={index} // Use uma key única, idealmente um ID único para cada alerta
            text={alerta.msg}
            title={alerta.title}
            type={alerta.type}
            close={()=>{deletaMessage(index)}}
          />
        ))}
      </Alert.Root>
    )
  );
};