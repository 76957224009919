import { functions } from ".";
import { httpsCallable } from "firebase/functions";

export const GetUserAuthenticatorFromEmail = async (email) => {
    // usa código do FUNTIONS para COLETAR O UID o usuário do authenticator
    const getUserByEmail = httpsCallable(functions, 'getUserByEmail');
    try {
        const result = await getUserByEmail({ email });
        return result.data;
    } catch (error) {
        console.log('Error:', error.message);
    }
};


export const DeleteUserAuthenticatorFromUid = async (uid) => {
    // usa código do FUNTIONS para deletar o usuário do authenticator
    const deleteUserByUid = httpsCallable(functions, 'deleteUserByUid');
    try {
      const result = await deleteUserByUid({ uid });
      return result.data.message;
    } catch (error) {
      console.error('Error:', error.message);
    }
  };