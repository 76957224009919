import React, { useState } from 'react';
import { Navbar } from '../../../components/layouts/navbar';
import Logo from '../../../assets/logo_temporario.png'
import { Outlet } from 'react-router-dom';

export const URoot = () => {

    const [active, setActive] = useState(false);

    return (
            <>
            <Navbar.Root active={active} setActive={setActive} logo={Logo}>
                <Navbar.End>
                    <Navbar.Item  link={"/"}>Pagina Padrão</Navbar.Item>
                    <Navbar.Item  link={"/componentes"}>Componentes</Navbar.Item>
                    <Navbar.Item  link={"/coleta/1234ID/teste/1234Evento"}>Coleta</Navbar.Item>
                    <Navbar.Item  link={"/testeBanco"}>Teste Banco</Navbar.Item>
                </Navbar.End>
            </Navbar.Root>
            
            {/* Faz com que o conteudo mude mudando de pagina */}
            <Outlet/>
            </>
    );
};
