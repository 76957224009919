import { storage } from "."; 
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";


/**
 * Obtém a URL de download de um arquivo no Firebase Storage.
 * 
 * @param {String} storagePath - Caminho para acessar a imagem no Storage.
 * @returns {Promise<String>} - Retorna uma Promise que resolve para a URL do arquivo.
 */
export const getUrlFile = async (storagePath) => {
    try {
        const fileRef = ref(storage, storagePath);
        const url = await getDownloadURL(fileRef);
        return url;
    } catch (error) {
        throw new Error(`Erro ao obter a URL do arquivo: ${error.message}`);
    }
};

/**
 * Deleta um arquivo no Firebase Storage.
 * 
 * @param {String} storagePath - Caminho para acessar a imagem no Storage.
 * @returns {Promise<String>} - Retorna uma Promise que resolve para uma mensagem de sucesso.
 */
export const deleteFile = async (storagePath) => {
    try {
        const fileRef = ref(storage, storagePath);
        await deleteObject(fileRef);
        return "Arquivo deletado com sucesso";
    } catch (error) {
        throw new Error(`Erro ao deletar o arquivo: ${error.message}`);
    }
};


/**
 * Faz o upload de um arquivo para o Firebase Storage.
 * 
 * @param {File} file - O arquivo a ser carregado.
 * @param {String} storagePath - Caminho para salvar a imagem no Storage.
 * @param {Function} setUploadProgress - Função para atualizar o progresso do upload.
 * @returns {Promise<String>} - Retorna uma Promise que resolve para a URL do arquivo carregado.
 */
export const uploadFile = async (file, storagePath) => {
    try {
        const fileRef = ref(storage, storagePath);
        const uploadTask = uploadBytesResumable(fileRef, file);

        return new Promise((resolve, reject) => {
            uploadTask.on(
                'state_changed',
                (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log('Salvando Imagem', progress);
                    // setUploadProgress(progress);
                },
                (error) => {
                    reject(new Error(`Erro ao fazer o upload do arquivo: ${error.message}`));
                },
                async () => {
                    try {
                        const url = await getUrlFile(storagePath);
                        resolve(url);
                    } catch (error) {
                        reject(new Error(`Erro ao obter a URL do arquivo: ${error.message}`));
                    }
                }
            );
        });
    } catch (error) {
        throw new Error(`Erro ao fazer o upload do arquivo: ${error.message}`);
    }
};


export const getPathFile = (url) => {
    const urlObj = new URL(url);
    const caminhoCodificado = urlObj.pathname.split('/o/')[1];
    const caminhoDecodificado = decodeURIComponent(caminhoCodificado);
    return caminhoDecodificado;
  }
