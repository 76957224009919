import React from 'react';


export const PageHeroRoot = ({ children, style={}, className="", color="", size="" }) => {
  return (
    <div className={`hero ${className} ${color} ${size}`} style={style}>
      {children}
    </div>
  );
};
