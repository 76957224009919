import { ColumnsContent } from "./ColumnsContent";
import { ColumnsRoot } from "./ColumnsRoot";

/**
 * # Root
* Cria um componente com a classe "columns".
 *
 * @param {React.ReactNode} children - O conteúdo a ser exibido dentro do componente.
 * @param {React.CSSProperties} style - Estilo CSS adicional para o componente.
 * @param {string} className - Classes adicionais para o componente ("is-vcentered", "is-multiline", "is-mobile", "is-desktop", "is-gapless")
 * @param {string} margin - Classes margin entre as colunas  ("is-< 0 ao 8 >")
 * @returns {JSX.Element} O componente React.
 * 
 * # Content
* Cria um componente com a classe "column".
 *
 * @param {React.ReactNode} children - O conteúdo a ser exibido dentro do componente.
 * @param {React.CSSProperties} style - Estilo CSS adicional para o componente.
 * @param {string} className - Classes adicionais para o componente ("is-vcentered", "is-multiline", "is-mobile", "is-desktop")
 * @param {string} size - Classes de tamanhos das colunas bulma ("is-<1 ao 12>", "is-narrow", "is-three-quarters", "is-two-thirds", "is-half", "is-one-third", "is-one-quarter", "is-full")
 * @param {string} offset - Classes de espaços entre as colunas bulma ("is-offset-<1 ao 12>", is-offset-three-quarters", "is-offset-two-thirds", "is-offset-half", "is-offset-one-third", "is-offset-one-quarter", "is-offset-full")  
 * @param {string} narrow - Classes para estreitar a coluna as colunas bulma ("is-narrow-mobile", "is-narrow-tablet", "is-narrow-touch", "is-narrow-desktop", "is-narrow-widescreen", "is-narrow-fullhd")
 * @returns {JSX.Element} O componente React.
 * 
 */


export const Column = {
    Root: ColumnsRoot,
    Content: ColumnsContent,
}


// <Column.Root>
//     <Column.Content>
//         conteudo dentro da column
//     </Column.Content>
//     <Column.Content>
//         conteudo dentro da column
//     </Column.Content>
// </Column.Root>


