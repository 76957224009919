import React from "react";


export const SelectRoot = ({children, className="", style={}}) => {
    return(
        <div class="control has-icons-left" className={className} style={style}>
          {children}
        </div>
    )
}


//     <Select.Root>
//     <Select.Content color="danger" size="small">
//         <Select.Option value='valor 1' title='Valor 1' selected={true}></Select.Option>
//         <Select.Option value='valor 2' title='Valor 2' ></Select.Option>
//     </Select.Content>
//     <Select.Icon>
//         X
//     </Select.Icon>
// </Select.Root>
