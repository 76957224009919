import { PaginationRoot } from "./PaginationRoot"
import { PaginationPrevious } from "./PaginationPrevious"
import { PaginationNext } from "./PaginationNext"
import { PaginationList } from "./PaginationList"
import { PaginationLink } from "./PaginationLink"

/**
 * # Root
 * 
 * Cria um componente para comportar o PAGINATION.
 *
 * @param {React.ReactNode} children - Aceita somente o componente de BOTÃO DE CLOSE.
 * @param {string} className - Aceita classes do bulma
 * @param {string} size - Aceita classe de tamano do bulma ("is-small", "is-medium", "is-large")
 * @param {string} align -Aceita classe de alinhamento do bulma ("is-left", "is-right", "is-centered")
 * @returns {JSX.Element} O componente React.
 * 
 * # Previous
 * 
 * Cria um Componente de botão com o PREVIOUS.
 *
 * @param {React.ReactNode} children - Aceita somente o componente de BOTÃO DE CLOSE.
 * @param {boolean} disabled - Faz com que o botão seja desabilitado.
 * @param {string} link - Recebe um path para redirecionar o usuário ("/CaminhoParaAPagina").
 * @returns {JSX.Element} O componente React.
 * 
 * # Next
 * 
 * Cria um Componente de botão com o NEXT.
 *
 * @param {React.ReactNode} children - Aceita somente o componente de BOTÃO DE CLOSE.
 * @param {boolean} disabled - Faz com que o botão seja desabilitado.
 * @param {string} link - Recebe um path para redirecionar o usuário ("/CaminhoParaAPagina").
 * @returns {JSX.Element} O componente React.
 * 
 * # List
 * 
 * Cria um Componente para acomodar os bõtes do pagination.
 *
 * @param {React.ReactNode} children - Aceita somente o componente de BOTÃO DE CLOSE.
 * @returns {JSX.Element} O componente React.
 * 
 * # Link
 * 
 * Cria um Componente de botão para fechar o MODAL, ele ficará no canto SUPERIOR DIREITO.
 *
 * @param {React.ReactNode} children - Aaceita somente o componente de BOTÃO DE CLOSE.
 * @param {string} link - Aceita o SETVALUE para colocar como false e fechar o modal
 * @returns {JSX.Element} O componente React.
 */

export const Pagination = {
    Root: PaginationRoot,
    Previous: PaginationPrevious,
    Next: PaginationNext,
    List: PaginationList,
    Link: PaginationLink
}


