import React from "react";

import { Link } from "react-router-dom";




export const ButtonContentA = ({children,  color="light", colorText=null, size="small", link=null, className=null, loading=null, disabled=null, msgDisabled=""}) => {

    return (
        <Link 
            className={`button ${color} ${size} ${colorText} ${className}  ${loading && "is-loading"}`} 
            to={link}
            disabled={disabled}
            title={disabled && msgDisabled}> 
            {children}
        </Link>
    )

}