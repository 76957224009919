import React from "react";



export const ButtonContents = ({children}) => {
    return (
        <p className="control">
         {children}
        </p>
    )
}