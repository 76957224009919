import React, { useState } from 'react';
import { useGlobalContext } from '../../../context/ContextGlobal';
import { DateTimeNow } from '../../../funtions/dates';
import { useNavigate } from 'react-router-dom';
import { Page } from '../../../components/layouts/page';
import { Column } from '../../../components/layouts/columns';
import { Form } from '../../../components/ui/form';
import { TratamentoError } from '../../../funtions/erros';
import { ConcentFormRoot } from './_FormConsentimentos';
import { addDocument } from '../../../firebase/firestore';


export const AAdicionarConsentimentos = () => {

    const {user, setAlerts} = useGlobalContext();
    const [loading, setLoading] = useState(false);
    const [ consentimento, setConsentimento ] = useState({});
    const navigate = useNavigate();



    const criarConsentimento = async  () => {
        try{
            setLoading(true);
            const new_plan = {...consentimento, created_by:user?.user?.nome, dt_created:DateTimeNow()};
            await addDocument('consentimentos', new_plan);
            return navigate('/administrator/configuracoes/consentimentos');
        }
        catch(error){
            return setAlerts((prev) => ([...prev, {title:'Error:', msg:TratamentoError(error) , type:'error'}]));
        }
        finally{
            setLoading(false);
        }
    }

    return (
        <Page.Section className='is-fullheight-with-navbar'>
            <Form.Root action={() => {criarConsentimento();}}>
                    <Column.Root>
                        <Column.Content>
                            <p className="subtitle is-size-3">Adicionar Consentimento</p>
                        </Column.Content>
                    </Column.Root>
                    <ConcentFormRoot loading={loading} values={consentimento} setValues={setConsentimento} disabledList={{}} />
            </Form.Root >
        </Page.Section>
    );
};
