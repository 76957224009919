import React from "react";



export const CheckboxContent = ({value, setValue, field=null, disabled=false}) => {

    const changeValue = (event) => {
        if(field){
            setValue((prev) => ({...prev, [field]:event.target.checked}));
        }
        else{
            setValue(event.target.checked);
        }
    };

    const checked = field ? value[field] : value;

    return (
        <input className="mr-1" type="checkbox" disabled={disabled} onChange={changeValue} checked={checked}/>
    )
}