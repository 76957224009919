import React from "react";

/**
 * Cria um componente que alocará todo o navbar.
 *
 * @param {React.ReactNode} children - Aceita somente NAVBARSTART ou NAVBAREND.
 * @param {useState.Value} active - Variavel que controla se o navbar mobile esta ativo ou não (true ou false).
 * @param {useState.setValue} setActive - Variavel para setar o active.
 * @param {image} logo - Aceita uma imagem que mostrará como logo.
 * @param {string} color - Classes de cores do bulma ("is-white", "is-black", "is-light", "is-dark", "is-primary", "is-link", "is-info", "is-success", "is-warning", "is-danger")
 * @returns {JSX.Element} O componente React.
 */

export const NavbarRoot = ({children, active, setActive, logo, color="is-dark"}) => {

    return(
        <nav className={`navbar ${color} is-fixed-top`} role="navigation" >
        <div className="navbar-brand">
      
          <img src={logo} alt="Keap Staff" style={{width:70, height:70, marginBottom:5}}/>
      
          <a role="button" className={`navbar-burger ${active ? "is-active" : ""}`} onClick={() => { setActive(!active) }} href={null}>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>
      
        <div className={`navbar-menu ${active ? "is-active" : ""}`}>
         {children}
        </div>
      </nav>
    )
}