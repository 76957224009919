import React from 'react';
import { Page } from '../../components/layouts/page/index';
import { AlertFunctional } from '../../components/ui/alert/AlertFunctional';
import { Outlet } from '../../../node_modules/react-router-dom/dist/index';
import { useGlobalContext } from '../../context/ContextGlobal';
import { Hero } from '../../components/layouts/hero/index';
import { Column } from '../../components/layouts/columns/index';
import { Card } from '../../components/ui/card/index';


export const LoginRoot = () => {

    const {alerts, setAlerts} = useGlobalContext();

    return (  
        <Hero.Root color="has-background-link-dark" size='is-fullheight is-fullwidth' >
            <Hero.Body>
                <Page.Root>
                    <Column.Root className='is-centered'>
                        <Column.Content size='is-8'>
                            <Outlet />
                        </Column.Content>
                    </Column.Root>
                </Page.Root>
            </Hero.Body>
            <AlertFunctional values={alerts} setValues={setAlerts}></AlertFunctional>
        </Hero.Root>
    );
};
