import { auth } from ".";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { signInWithEmailAndPassword, sendPasswordResetEmail, sendEmailVerification, getAuth, deleteUser} from "firebase/auth";
import { signOut } from "firebase/auth";

export const register = async (email, password) => {
    try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        return {uid: user.uid};
    } catch (error) {
        return { error: error.message || 'Erro desconhecido ao criar usuário' };
    }
};

export const logIn = async (email, password) => {
    try {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        return {'success':user};
    } catch (error) {
        return { error: error.message || 'Erro desconhecido ao criar usuário' };
    }
};

export const logOut = async () => {
    try {
        await signOut(auth);
    } catch (error) {
        console.error('Erro ao deslogar usuário:', error.message);
    }
};

export const resetPassword = (email) => {
    try {
        sendPasswordResetEmail(auth, email);
        return {'success':true};
    } catch (error) {
        return {'error':error};
    }
};

export const sendVerificationEmail = async () => {
        const auth = getAuth();
        sendEmailVerification(auth.currentUser)
        .then((response) => {
            return {sucess:response}
        }).catch((error)=>{
            return {error:error}
        });
};

