import React from "react";


export const TableBody = ({children}) =>{

    return(
        <tbody>
            {children}
        </tbody>
    )
}