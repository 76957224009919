import React from "react";
import { Link } from "react-router-dom";
import { useGlobalContext } from "../../../context/ContextGlobal";


/**
 * Cria um componente de item para o navbar.
 *
 * @param {React.ReactNode} children - Só aceita string ou grupos de buttons
 * @param {string} link - Link que redirecionara o usuário para outra pagina (passe o 'path' do ROUTER) 
 * @param {string} colorBackgroud - Classes de cores do bulma ("has-background-white", "has-background-black", "has-background-light", "has-background-dark", "has-background-primary", "has-background-link", "has-background-info", "has-background-success", "has-background-warning", "has-background-danger")
 * @param {string} colorBackgroud - Classes de cores do bulma ("has-text-white", "has-text-black", "has-text-light", "has-text-dark", "has-text-primary", "has-text-link", "has-text-info", "has-text-success", "has-text-warning", "has-text-danger")
 * @returns {JSX.Element} O componente React.
 */

export const NavbarItem = ({children, link=null, colorBackgroud=null , colorText=null, action=()=>{}}) => {

    const {setNavBar} = useGlobalContext();

    let classe = `navbar-item ${colorBackgroud} ${colorText}`

    return(
      <Link to={link} className={classe} onClick={()=>{action(); setNavBar(false);}}>
        <p className="mx-2">
          {children}
        </p>
      </Link>
    )
}