import React, { useState } from 'react';
import { useGlobalContext } from '../../context/ContextGlobal';
import { Input } from '../../components/ui/input';
import { Icon } from '../../components/icons';
import { Button } from '../../components/ui/button';
import { Column } from '../../components/layouts/columns';
import { Pagination } from '../../components/ui/pagination';
import { Card } from '../../components/ui/card';
import { resetPassword } from '../../firebase/auth';
import { useNavigate } from 'react-router-dom';


export const Esquecisenha = () => {

    const [email, setEmail] = useState('');
    const {alerts, setAlerts} = useGlobalContext();
    const navigate = useNavigate();

    const enviarEmaildeResetDeSenha = async () => {
        if(email.length > 0){
            const response = await resetPassword(email);
            if('success' in response){
                setAlerts([...alerts, {'type':'success', 'title':"E-mail Enviado", 'msg':`Um E-mail de redefinição de senha, foi enviado para o e-mail: ${email}`}])
                navigate('/');
            }else{
                setAlerts([...alerts, {'type':'error', 'title':"E-mail invalido", 'msg':"Porfavor, verifique o seu e-mail e tente novamente"}])
            }
            
        }
        else{
            setAlerts([...alerts, {'type':'error', 'title':"E-mail não preenchido", 'msg':"preencha o e-mail e tente novamente."}])
        }
        
    }

    return ( 
        <>
        <Card.Root>
            <Card.Content>
                <Pagination.Root align='is-left' className='is-rounded ml-2 mt-2' size='is-medium'>
                    <Pagination.List>
                        <Pagination.Link link={"/"}>
                            <Icon size={20} >arrowLeft</Icon>
                        </Pagination.Link>
                    </Pagination.List>
                </Pagination.Root>
                <Column.Root className='mx-5'>
                    <Column.Content>
                        <h1 className='subtitle is-size-2 has-text-centered'>Trocar Senha</h1>
                        <div className="my-5">
                            <Input.Root>
                                <Input.Contents alignIcon={'left'}>
                                    <Input.Prompt className='is-rounded' placeholder='E-mail' type='email' value={email} setValue={setEmail} />
                                    <Input.Icon>
                                        <Icon size={20}>user</Icon>
                                    </Input.Icon>
                                </Input.Contents>
                            </Input.Root>
                        </div>
                        <div className="my-5 mt-6 ">
                            <Button.Bt className="is-rounded has-background-link-light is-fullwidth " colorText="has-text-link-dark" action={() => { enviarEmaildeResetDeSenha(); }}>Entrar</Button.Bt>
                        </div>
                    </Column.Content>
                </Column.Root>
            </Card.Content>
        </Card.Root>
        </> 
    );
};
